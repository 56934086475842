import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { RequestError } from 'api/httpEntities';
import { archiveProfile, restoreProfile } from 'api/archive/archives';
import { QUERY_KEY_ROOTS } from 'variables';
import { ArchiveProfileData } from './archiveApiTypes';

export const useArchiveProfile = (
  learnerId: string,
  organisationId: string,
): UseMutationResult<void, RequestError, ArchiveProfileData, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: archiveProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA, learnerId, organisationId],
      });
    },
  });
};

export const useRestoreProfile = (
  learnerId: string,
  organisationId: string,
): UseMutationResult<void, RequestError, ArchiveProfileData, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: restoreProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA, learnerId, organisationId],
      });
    },
  });
};
