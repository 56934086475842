import React from 'react';
import { TextV2 } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, Button, Card, CardContent } from '@mui/material';
import { useBetaEnabled } from 'features';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventResponse } from 'api';
import { TProfilesCount } from 'pages/Event/types';

interface PurchasedProfilesCardProps {
  event: EventResponse;
  profilesStatusCount: TProfilesCount;
  disabled?: boolean;
}

export const PurchasedProfilesCard = ({
  event,
  profilesStatusCount,
  disabled = false,
}: PurchasedProfilesCardProps) => {
  const betaEnabled = useBetaEnabled('schedule-share-feature');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!betaEnabled || event.eventType === 'INSIGHTS_EXPLORE') {
    return null;
  }

  const { noOfProfilesReadyToPurchase, noOfProfilesPurchased } =
    profilesStatusCount;
  const inactive =
    noOfProfilesReadyToPurchase === 0 && noOfProfilesPurchased === 0;
  const evaluatorsComplete =
    noOfProfilesPurchased + noOfProfilesReadyToPurchase;
  const isDisabled: boolean =
    disabled || noOfProfilesReadyToPurchase === 0 || inactive;

  return (
    <Card
      elevation={0}
      sx={() => ({
        height: '100%',
        opacity: inactive ? '0.5' : 'undefined',
      })}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'flex-start',
        }}
      >
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
          <TextV2 component="p" variant="bodyBold">
            {t('ui.event-management.events.profiles-purchased-tile.title')}
          </TextV2>
        </Box>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
          <TextV2 variant="h2" component="p">
            {noOfProfilesPurchased} / {evaluatorsComplete}
          </TextV2>
        </Box>
        <Button
          onClick={() => navigate(`${pathname}/download-profiles`)}
          fullWidth
          variant="contained"
          color="primary"
          disabled={isDisabled}
        >
          {t(
            'ui.event-management.events.profiles-purchased-tile.purchase-button',
          )}
        </Button>
      </CardContent>
    </Card>
  );
};
