/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Canvg } from 'canvg';
import { isChrome } from '@insights-ltd/design-library/utils';
import {
  canvasToBlob,
  getSvgElementById,
  renderCanvasToBlob,
  svgDataCanvasAndContextFromSvgElement,
} from 'pages/DownloadTeamWheel/globalMethods';

const renderSVGToBlob = (
  svgId: string,
  forceNativeInChrome = false,
  setWidth?: number,
  setHeight?: number,
): Promise<Blob> =>
  new Promise<Blob>((resolve, reject) => {
    const $svg = getSvgElementById(svgId);
    if (!$svg) reject(new Error('Invalid svgId!'));
    const width = setWidth || $svg.width.baseVal.value * 2;
    const height = setHeight || $svg.height.baseVal.value * 2;
    const { canvas, ctx, svgData } = svgDataCanvasAndContextFromSvgElement(
      $svg,
      width,
      height,
    );
    if (isChrome() && !forceNativeInChrome) {
      const v = Canvg.fromString(ctx!, svgData);
      v.render()
        .then(() => {
          if (isChrome()) {
            canvasToBlob(canvas)
              .catch(() => reject(new Error('Save to blob failed!')))
              .then((blob) => resolve(blob as Blob));
          }
        })
        .catch(() => {
          reject(new Error('canvg render failed!'));
        });
    } else {
      renderCanvasToBlob(svgData, ctx!, canvas, width, height)
        .catch(() => reject(new Error('Native render to blob failed!')))
        .then((blob) => resolve(blob as Blob));
    }
  });

export default renderSVGToBlob;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
