/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { queryStatus } from 'utils/queryStatus';
import {
  OrganisationResponse,
  Score,
  TeamLearnerResponse,
  transformTeamWheelByOrganisationStructure,
  useEventInvitees,
  useGetEvent,
  useGetEventTeamScores,
  useGetTeam,
  useGetTeamColourCountsByOrganisation,
  useGetTeamLearners,
  useGetTeamWheelScores,
  useGetTeamWheelSummaryByOrganisation,
} from 'api';
import { TEAM_WHEEL_SCORE_THRESHOLD } from 'variables';
import { getPurchaseCount } from './scoreUtils';

export type TeamWheelType = 'Experience' | 'Team';

type Props = {
  eventId: string | undefined;
  type: TeamWheelType;
  teamId: string | undefined;
};

const getFilterOrganisations = (organisations: OrganisationResponse[] = []) => {
  const result: Record<string, boolean> = {};

  organisations.forEach(({ id }) => {
    result[id] = true;
  });

  return result;
};

const getLearnerOrganisations = (learners: TeamLearnerResponse[] = []) => {
  const result: Record<string, { id: string; name: string }> = {};

  learners.forEach(({ id, latestProfile }) => {
    if (latestProfile) {
      result[id] = {
        id: latestProfile.organisation.id,
        name: latestProfile.organisation.name,
      };
    }
  });

  return result;
};

const sortByValueName = <ItemType extends { id: string; name: string }>(
  values: ItemType[],
) => values.sort((a, b) => a.name.localeCompare(b.name));

export const useGetTeamWheelData = ({ eventId, type, teamId }: Props) => {
  const { status: eventStatus, data: event } = useGetEvent(eventId || '', {
    enabled: type === 'Experience',
  });

  const { status: eventScoresStatus, data: eventScores = [] } =
    useGetEventTeamScores(eventId || '', {
      enabled: type === 'Experience',
    });

  const { status: inviteesStatus, data: invitees } = useEventInvitees(
    eventId || '',
    {
      enabled: type === 'Experience',
    },
  );

  const { status: teamStatus, data: team } = useGetTeam(teamId || '', {
    enabled: type === 'Team',
  });

  const { status: teamLearnersStatus, data: teamLearnersData } =
    useGetTeamLearners(
      { teamId: teamId ?? '', searchTerm: '', purchaseStatus: 'PURCHASED' },
      { enabled: !!teamId },
    );

  const {
    status: wheelSummaryFetchStatus,
    data: { wheelPositions } = { wheelPositions: [] },
  } = useGetTeamWheelSummaryByOrganisation(teamId!, {
    enabled: type === 'Team',
  });

  const { status: teamColourCountStatus, data: { counts } = { counts: [] } } =
    useGetTeamColourCountsByOrganisation(teamId!, {
      enabled: type === 'Team',
    });

  const activeOrganisations = getFilterOrganisations(
    team?.organisationContext?.organisations,
  );
  const { totalScoreCount: summaryScoreCount } =
    transformTeamWheelByOrganisationStructure(
      activeOrganisations,
      wheelPositions,
    );

  const isLearnerCountExceeded = !!(
    summaryScoreCount && summaryScoreCount > TEAM_WHEEL_SCORE_THRESHOLD
  );

  const { status: teamScoresStatus, data: teamScoreData } =
    useGetTeamWheelScores(teamId || '', {
      enabled:
        type === 'Team' &&
        !isLearnerCountExceeded &&
        wheelSummaryFetchStatus !== 'pending',
    });

  const learnerOrganisationHashMap = getLearnerOrganisations(
    teamLearnersData?.learners,
  );

  const teamScores: (Score & { organisationId: string })[] =
    teamScoreData?.scores.map(({ id, ...score }) => ({
      ...score,
      id,
      organisationId: learnerOrganisationHashMap[id]?.id,
      organisationName: learnerOrganisationHashMap[id]?.name,
    })) ?? [];

  const teamScoreFetchStatus = isLearnerCountExceeded
    ? queryStatus(wheelSummaryFetchStatus, teamColourCountStatus)
    : queryStatus(
        wheelSummaryFetchStatus,
        teamColourCountStatus,
        teamScoresStatus,
      );

  const responseStatus =
    type === 'Experience'
      ? queryStatus(eventStatus, eventScoresStatus, inviteesStatus)
      : queryStatus(teamStatus, teamScoreFetchStatus, teamLearnersStatus);

  const teamOrganisations = team?.organisationContext?.organisations ?? [];

  const purchasedCount = getPurchaseCount(
    type,
    summaryScoreCount,
    eventScores.length,
    teamScores.length,
    isLearnerCountExceeded,
  );

  return {
    responseStatus,
    wheelPositions,
    teamScores,
    counts,
    invitees,
    team,
    eventScores,
    event,
    isLearnerCountExceeded,
    activeOrganisations,
    teamOrganisations: sortByValueName(teamOrganisations),
    purchasedCount,
  };
};
/* eslint-enable @typescript-eslint/no-non-null-assertion */
