/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import {
  H1,
  Switch,
  SvgAddRegular,
} from '@insights-ltd/design-library/components';
import { useQueryClient } from '@tanstack/react-query';
import { queryStatus } from 'utils/queryStatus';
import Notification from 'components/Notification';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useClearHistoryState from 'components/hooks/useClearHistoryState';
import { useAuth } from 'contexts/AuthContext';
import OrganisationInfoSection from 'pages/Account/OrganisationInfoSection';
import TransactionsSection from 'pages/Account/TransactionsSection';
import ExperiencesSection from 'pages/Account/ExperiencesSection';
import LeadingColourEnergyIndicator from 'components/LeadingColourEnergyIndicator';
import {
  GroupType,
  OrganisationResponse,
  useDeletePractitionerFromOrg,
  useGetAllGroups,
  useUpdatePractitionerAccess,
} from 'api';
import Button from '@mui/material/Button';
import { Wallet } from 'components/Wallet';
import UserInfoSection from 'pages/Account/views/UserInfoSection';
import RemoveOrganisationDialog from 'components/RemoveOrganisationDialog/RemoveOrganisationDialog';
import {
  createGroupOrganisationHashMap,
  GroupOrganisationHashMap,
} from 'utils/createGroupOrganisationMap';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { QUERY_KEY_ROOTS } from 'variables';
import { styled } from '@mui/material';
import PermissionGuard from 'components/PermissionGuard';
import useGetAccountData from './useGetAccountData';
import ConfirmAccessDialog, { ConfirmType } from './ConfirmAccessDialog';

type AccountParams = {
  organisationId: string;
  practitionerId: string;
};

const getOrganisationText = (
  orgId: string,
  organisations: OrganisationResponse[],
  organisationGroups: GroupOrganisationHashMap,
  t: (str: string, options?: any) => string,
) => {
  const organisation =
    organisations.find(({ id }) => id === orgId) ??
    ({} as OrganisationResponse);
  const group = organisationGroups[orgId];

  const groupTypeLabel: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.group.label.customer'),
    PARTNER: t('ui.event-management.organisations.group.label.partner'),
  };

  if (organisation && group) {
    return t(
      'ui.event-management.practitioner-account.revoke-organisation.grouped-organisation',
      {
        organisation: organisation.name,
        groupType: groupTypeLabel[group?.type ?? 'PARTNER'],
        groupText: group.name,
      },
    );
  }

  return t(
    'ui.event-management.practitioner-account.revoke-organisation.ungrouped-organisation',
    { organisation: organisation.name },
  );
};

const StyledAddIcon = styled(SvgAddRegular)(({ theme: th }) => ({
  stroke: th.palette.common.white,
  height: '16px',
  marginRight: '2px',
}));

const StyledLeadingColourEnergyIndicator = styled(LeadingColourEnergyIndicator)(
  ({ theme }) => ({
    width: theme.spacing(spacingSizeMap.L),
    height: theme.spacing(spacingSizeMap.L),
    marginTop: theme.spacing(spacingSizeMap.XS),
  }),
);

const PractitionerAccount = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showAccessDialog, setShowAccessDialog] = useState<
    ConfirmType | undefined
  >();

  const [organisationToRemove, setOrganisationToRemove] = useState<
    string | null
  >(null);
  const { state } = useLocation();
  const locationState: any = state;
  const { practitionerId, organisationId } = useParams<AccountParams>();
  const { status: groupStatus, data: allGroups } = useGetAllGroups({
    enabled: user?.permissions.Organisation_Group_ReadAll !== 'None',
  });

  const allGroupStatus =
    user?.permissions.Organisation_Group_ReadAll !== 'None'
      ? groupStatus
      : 'success';
  const { status: accountStatus, data } = useGetAccountData(
    practitionerId || '',
    organisationId || '',
  );
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isProcessing,
    reset: resetMutation,
  } = useUpdatePractitionerAccess(practitionerId || '', queryClient);
  const { mutateAsync: removeOrg } = useDeletePractitionerFromOrg(
    organisationId ?? '',
    queryClient,
  );

  useClearHistoryState(!!locationState?.amount);

  const isAdminUser = user?.roles.includes('SUPER_ADMIN');

  const status = queryStatus(allGroupStatus, accountStatus);

  if (user?.id === practitionerId) {
    return <Navigate to="/account" />;
  }
  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.my-account.error.load')}
      />
    );
  }

  const {
    practitioner,
    wallet,
    organisation,
    transactions,
    cancelledEvents,
    activeEvents,
    practitionerOrganisations,
    groups,
    supportedRoles,
  } = data;
  const { emailAddress, leadingColourEnergy, fullName, roles } = practitioner!;
  const { availableUnits } = wallet!;
  const { name } = organisation!;
  const showGroups = roles.includes('ADVANCED_USER');
  let notification = null;

  if (locationState?.amount) {
    switch (locationState?.transactionType) {
      case 'TRANSFER_FROM': {
        notification = t(
          'ui.event-management.transfer-units.transfer.from.notification',
          {
            amount: locationState.amount,
            fullName: practitioner?.fullName,
          },
        );
        break;
      }
      case 'TRANSFER_TO': {
        notification = t(
          'ui.event-management.transfer-units.transfer.to.notification',
          {
            amount: locationState.amount,
            fullName: practitioner?.fullName,
          },
        );
        break;
      }
      default: {
        notification = t('ui.event-management.manage-units.add.notification', {
          amount: locationState.amount,
          fullName: practitioner?.fullName,
        });
        break;
      }
    }
  }

  const title = t('ui.event-management.account.title', {
    fullName: practitioner?.fullName,
  });

  const accessChangedHandler = (newState: boolean) => {
    const confirmType = newState ? 'grant' : 'remove';
    setShowAccessDialog(confirmType);
  };
  const confirmAccessChange = (newState?: ConfirmType) => {
    if (newState) {
      const enabled = newState === 'grant';
      mutate(
        { practitionerId: practitionerId || '', enabled },
        { onSuccess: () => setShowAccessDialog(undefined) },
      );
    } else {
      resetMutation();
      setShowAccessDialog(undefined);
    }
  };

  const noResultsTranslations = {
    cancelled:
      'ui.event-management.practitioner-account.experiences.cancelled.no-results',
    active:
      'ui.event-management.practitioner-account.experiences.upcoming.no-results',
    archived:
      'ui.event-management.practitioner-account.experiences.past.no-results',
  };

  const addToOrgTranslations = !isAdminUser
    ? t('ui.event-management.account.add-to-my-organisation')
    : t('ui.event-management.account.add-to-organisation');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.dashboard.home.title'),
                '/organisations': t('ui.event-management.organisations.title'),
                [`/organisations/${organisationId}`]: name,
              }}
              activeText={fullName}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid container wrap="nowrap" item xs={12} md={5}>
              <StyledLeadingColourEnergyIndicator
                leadingColourEnergy={
                  leadingColourEnergy === null ? undefined : leadingColourEnergy
                }
              />
              <H1 variant="h2">{fullName}</H1>
            </Grid>
            <Grid item xs={12} md="auto">
              <Grid
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                container
                spacing={2}
              >
                <PermissionGuard
                  requiredPermissions={[
                    {
                      action: 'Practitioner_Update',
                      scope: 'Global',
                    },
                  ]}
                >
                  <Grid
                    item
                    sx={{
                      '&  > div': {
                        marginTop: '0 !important',
                      },
                    }}
                  >
                    <Switch
                      checked={Boolean(practitioner?.enabled)}
                      onChange={accessChangedHandler}
                      labelLeft={t('ui.event-management.access.label.removed')}
                      labelRight={t('ui.event-management.access.label.granted')}
                    />
                  </Grid>
                </PermissionGuard>
                <Grid item>
                  <PermissionGuard
                    requiredPermissions={[
                      {
                        action: 'Add_User_to_Partner_Organisation',
                        scope: 'Global',
                      },
                    ]}
                  >
                    <Button
                      startIcon={<StyledAddIcon />}
                      variant="contained"
                      color="primary"
                      sx={{ marginRight: '0.5rem', padding: '10px 20px' }}
                      to={`/organisations/${organisationId}/practitioners/${practitionerId}/invite`}
                      component={RouterLink}
                      aria-label={addToOrgTranslations}
                    >
                      {addToOrgTranslations}
                    </Button>
                  </PermissionGuard>
                  <PermissionGuard
                    requiredPermissions={[
                      {
                        action: 'Practitioner_Update',
                        scope: 'Global',
                      },
                    ]}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      to={`/organisations/${organisationId}/practitioners/${practitionerId}/edit`}
                      component={RouterLink}
                      aria-label={t('ui.event-management.account.edit')}
                    >
                      {t('ui.event-management.account.edit')}
                    </Button>
                  </PermissionGuard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={(theme) => theme.spacing(spacingSizeMap.M)}
            mb={(theme) => theme.spacing(spacingSizeMap.L)}
          >
            <Divider />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8}>
              <>
                {notification && <Notification message={notification} />}
                <UserInfoSection
                  emailAddress={emailAddress}
                  practitioner={practitioner}
                />
                <OrganisationInfoSection
                  organisations={practitionerOrganisations!}
                  practitioner={practitioner}
                  groups={showGroups ? groups : []}
                  allowDemotion={isAdminUser}
                  onOrganisationRemove={(id) => {
                    setOrganisationToRemove(id);
                  }}
                  supportedRoles={supportedRoles ?? []}
                />
              </>
            </Grid>
            <Grid item xs={12} md={4}>
              <Wallet
                availableUnits={availableUnits}
                practitionerId={practitioner?.id}
                organisationId={organisation?.id}
              />
            </Grid>
          </Grid>
          <TransactionsSection
            transactions={transactions!}
            practitionerId={practitionerId!}
            organisationId={organisation?.id}
            isProfileView
          />
          <ExperiencesSection
            cancelledEvents={cancelledEvents!}
            activeEvents={activeEvents!}
            practitionerOrganisations={practitionerOrganisations}
            isAdminUser={
              !!practitioner?.roles.includes('SUPER_ADMIN') && isAdminUser
            }
            textKeys={noResultsTranslations}
          />
        </Container>
        <ConfirmAccessDialog
          open={Boolean(showAccessDialog)}
          confirmType={showAccessDialog}
          handleConfirm={() => confirmAccessChange(showAccessDialog)}
          onClose={() => confirmAccessChange(undefined)}
          processing={isProcessing}
          usersName={fullName}
        />
        <RemoveOrganisationDialog
          organisationText={getOrganisationText(
            organisationToRemove ?? '',
            practitionerOrganisations ?? [],
            createGroupOrganisationHashMap(allGroups ?? []),
            t,
          )}
          open={!!organisationToRemove}
          onSubmit={async () => {
            queryClient
              .invalidateQueries({
                queryKey: [QUERY_KEY_ROOTS.ORGANISATION_DATA],
              })
              .then();
            await removeOrg({
              organisationId: organisationToRemove ?? '',
              practitionerId: practitionerId ?? '',
            }).then(() => {
              navigate(`/organisations/${organisationToRemove}`);
            });
          }}
          onClose={() => {
            setOrganisationToRemove(null);
          }}
        />
      </FullHeight>
    </>
  );
};

export default PractitionerAccount;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
