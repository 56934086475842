import React from 'react';
import { useTranslation } from 'react-i18next';
import LocationIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormHeader from 'components/FormHeader';
import { LocationData, LocationForm } from 'components/EventForms';
import { useCreateEvent } from 'api';
import { toEventRequest } from 'utils/mappers/entityMappers';
import { StepProps } from './types';
import FormNavigation from './FormNavigation';

const Location = ({ onBack, event, onFormComplete }: StepProps) => {
  const navigate = useNavigate();
  const { mutate: createEvent, status, reset } = useCreateEvent();
  const { t } = useTranslation();

  const onSubmit = (formData: LocationData) => {
    const { organisation, ...eventRest } = event;
    const eventData = { ...eventRest, ...formData };

    onFormComplete();
    createEvent(
      {
        event: toEventRequest(eventData),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organisationId: organisation!.id,
      },
      {
        onSuccess: (response) =>
          navigate(`/experiences/${response.id}`, {
            state: { showCreatedDialog: true },
          }),
      },
    );
  };

  return (
    <>
      <FormHeader
        icon={LocationIcon}
        title={t('ui.event-management.events.create.location')}
      />
      <LocationForm event={event} onSubmit={onSubmit}>
        <FormNavigation
          onBack={onBack}
          lastStep
          loading={status === 'pending'}
        />
      </LocationForm>
      <Snackbar
        open={status === 'error'}
        autoHideDuration={6000}
        onClose={reset}
      >
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          {t('ui.event-management.events.create.create-event-error')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Location;
