import { queryStatus } from 'utils/queryStatus';
import { sortCancelledExperiences } from 'utils/mappers/sorting';
import useGetOrganisationGroups from 'components/hooks/useGetOrganisationGroups';
import { useMemo } from 'react';
import { isGroupManager } from 'utils/role';
import {
  EventSummaryResponse,
  Group,
  GroupResponse,
  OrganisationResponse,
  OrganisationSupportedRole,
  PractitionerData,
  TransactionResponse,
  WalletResponse,
  useGetAllSupportedRoles,
  useGetCancelledPractitionerEvents,
  useGetMyLatestTransactions,
  useGetOrganisation,
  useGetPractitionerById,
  useGetPractitionerEvents,
  useGetPractitionerOrganisations,
  useGetPractitionerWallet,
} from 'api';
import { QueryStatus } from '@tanstack/react-query';

const useGetAccountData = (
  practitionerId: string,
  organisationId: string,
): {
  status: QueryStatus;
  data: {
    practitioner: PractitionerData | undefined;
    wallet: WalletResponse | undefined;
    organisation: OrganisationResponse | undefined;
    groups: GroupResponse[];
    transactions: TransactionResponse[] | undefined;
    activeEvents: EventSummaryResponse[] | undefined;
    cancelledEvents: EventSummaryResponse[];
    practitionerOrganisations: OrganisationResponse[];
    organisationsInGroups: { [id: string]: Group };
    supportedRoles: OrganisationSupportedRole[] | undefined;
  };
} => {
  const { status: getPractitionerStatus, data: practitioner } =
    useGetPractitionerById(practitionerId);
  const { status: getWalletStatus, data: wallet } =
    useGetPractitionerWallet(practitionerId);
  const { status: getOrganisationStatus, data: organisation } =
    useGetOrganisation(organisationId);
  const { status: getTransactionsStatus, data: transactions } =
    useGetMyLatestTransactions(practitionerId, 3);
  const { status: activeEventsStatus, data: activeEvents } =
    useGetPractitionerEvents(practitionerId);
  const { status: cancelledEventsStatus, data: cancelledEvents } =
    useGetCancelledPractitionerEvents(practitionerId);
  const {
    status: practitionerOrganisationsStatus,
    data: practitionerOrganisations,
  } = useGetPractitionerOrganisations(practitioner?.id, {
    enabled: !!practitioner?.id,
  });
  const {
    status: organisationGroupStatus,
    data: organisationsInGroups,
    groups,
  } = useGetOrganisationGroups({
    practitionerId,
    enabled: true,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    organisations: practitionerOrganisations!,
  });
  const { data: supportedRoles, status: supportedRolesStatus } =
    useGetAllSupportedRoles();

  const status = queryStatus(
    getPractitionerStatus,
    getWalletStatus,
    getOrganisationStatus,
    getTransactionsStatus,
    activeEventsStatus,
    practitionerOrganisationsStatus,
    cancelledEventsStatus,
    organisationGroupStatus,
    supportedRolesStatus,
  );

  const availableOrganisations: OrganisationResponse[] = useMemo(() => {
    if (
      Object.keys(organisationsInGroups).length === 0 ||
      !isGroupManager(practitioner?.roles)
    ) {
      return practitionerOrganisations ?? [];
    }
    return (
      practitionerOrganisations?.filter(
        ({ id }) => !organisationsInGroups[id],
      ) ?? []
    );
  }, [organisationsInGroups, practitioner?.roles, practitionerOrganisations]);

  return {
    status,
    data: {
      practitioner,
      wallet,
      organisation,
      groups,
      transactions,
      activeEvents,
      cancelledEvents: sortCancelledExperiences(cancelledEvents),
      practitionerOrganisations: availableOrganisations,
      organisationsInGroups,
      supportedRoles,
    },
  };
};

export default useGetAccountData;
