/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { P } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { LearnerWithEvaluation } from 'api';
import { styled } from '@mui/material';
import { truncatedTextStyles } from './learnerHelper';

const StyledIcon = styled(PersonIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  fill: theme.palette.common.black,
  color: theme.palette.text.primary,
}));

const StyledP = styled(P)({
  ...truncatedTextStyles,
});

type LearnerInfoProps = {
  learner: LearnerWithEvaluation;
};
const LearnerInfo = ({ learner }: LearnerInfoProps) => {
  const { t } = useTranslation();

  return (
    <Grid item container spacing={2}>
      <Grid
        item
        xs={12}
        container
        sx={(theme) => ({
          mt: theme.spacing(spacingSizeMap.L),
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          '& > path': {
            width: '20px',
            height: '20px',
          },
        })}
      >
        <StyledIcon fontSize="large" />
        <Box mr={(theme) => theme.spacing(spacingSizeMap.S)} />
        <P variant="body-large">
          {t('ui.event-management.learner.learner-information')}
        </P>
      </Grid>

      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Divider sx={(theme) => ({ color: theme.palette.grey[500] })} />
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <P variant="body-bold" color="textSecondary">
              {t('ui.event-management.learner.full-name')}
            </P>
          </Grid>
          <Grid item>
            <StyledP variant="body-bold">{learner!.fullName}</StyledP>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <P variant="body-bold" color="textSecondary">
              {t('ui.event-management.learner.known-as')}
            </P>
          </Grid>
          <Grid item>
            <P variant="body-bold">{learner!.knownAs}</P>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <P variant="body-bold" color="textSecondary">
              {t('ui.event-management.learner.email-address')}
            </P>
          </Grid>
          <Grid item>
            <P variant="body-bold">
              <Link href={`mailto:${learner!.primaryEmail}`}>
                {learner!.primaryEmail}
              </Link>
            </P>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LearnerInfo;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
