/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {
  EventResponse,
  useGetEventReminder,
  useSetEventReminder,
  useCancelEventReminder,
  useSetImmediateEventReminder,
} from 'api';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library/components';
import { formatDate } from '@insights-ltd/design-library/utils';
import { DateTime } from 'luxon';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import { InviteeListOptions } from 'types/types';
import SetReminderDialog from './SetReminderDialog';

type InviteeStatus = {
  status: InviteeListOptions;
};

const titleKeys: Record<string, string> = {
  INSIGHTS_DISCOVERY_FULL_CIRCLE:
    'ui.event-management.events.dfc.evaluators.title',
  INSIGHTS_EXPLORE: 'ui.event-management.events.explore.evaluators.title',
};

type EvaluatorRemindersCardProps = {
  inviteesStatuses: InviteeStatus[];
  pastDeadline: boolean;
  pastExperience: boolean;
  event: EventResponse;
  disabled: boolean;
};

const StyledP = styled(P)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const completedEvaluatorStatuses = ({ status }: InviteeStatus) =>
  ['READY_TO_PURCHASE', 'PROFILE_GENERATED'].includes(status);

const EvaluatorRemindersCard = ({
  inviteesStatuses,
  pastDeadline,
  pastExperience,
  event,
  disabled,
}: EvaluatorRemindersCardProps) => {
  const { t } = useTranslation();
  const { data: eventReminder } = useGetEventReminder(event.id);
  const { mutate: setReminder } = useSetEventReminder(event.id);
  const { mutate: cancelReminder } = useCancelEventReminder(event.id);
  const { mutate: setImmediateReminder, isPending } =
    useSetImmediateEventReminder();

  const invitedCount = inviteesStatuses.filter(
    (invitee) => invitee.status !== 'ADDED',
  ).length;

  const invitedCountDFC = inviteesStatuses.filter(
    (invitee) =>
      (invitee.status !== 'ADDED' && invitee.status === 'PROFILE_GENERATED') ||
      invitee.status === 'READY_TO_PURCHASE',
  ).length;

  const evaluatedCount = inviteesStatuses.filter(
    completedEvaluatorStatuses,
  ).length;

  const inviteesCount: number = inviteesStatuses.length;
  const inactive: boolean = invitedCount === 0;
  const evaluatorsOutstanding: number = inviteesCount - evaluatedCount;
  const showAlert: boolean = pastDeadline && evaluatorsOutstanding > 0;
  const isDisabled: boolean =
    evaluatorsOutstanding === 0 || inactive || pastDeadline || disabled;
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [immediateReminderSent, setImmediateReminderSent] = useState(false);

  const handleSetReminder = ({
    reminderAt,
  }: {
    reminderAt: DateTime | null;
  }) => {
    const modifiedReminderAt = `${
      reminderAt!.toISO()!.split('T')[0]
    }T12:00:00.000Z`;

    setReminder(
      {
        eventId: event.id,
        reminderAt: modifiedReminderAt,
      },
      { onSuccess: () => setReminderDialogOpen(false) },
    );
  };

  const handleCancelReminder = () => {
    cancelReminder(
      { eventId: event.id },
      { onSuccess: () => setReminderDialogOpen(false) },
    );
  };

  const handleSetImmediateReminder = () => {
    setImmediateReminder(
      { eventId: event.id },
      {
        onSuccess: () => {
          setImmediateReminderSent(true);
        },
      },
    );
  };

  const isEventTypeDFC = event.eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE';

  const titleKey =
    titleKeys[event.eventType] || 'ui.event-management.events.evaluators.title';
  return (
    <Badge
      color="error"
      badgeContent={
        showAlert
          ? t('ui.event-management.events.evaluators.past-deadline')
          : ''
      }
      invisible={pastExperience || !showAlert}
      sx={{
        display: 'block',
        height: '100%',
      }}
    >
      <Card
        elevation={0}
        sx={(theme) => ({
          height: '100%',
          border: `1px solid ${
            pastDeadline && !pastExperience
              ? theme.palette.error.main
              : theme.palette.grey[500]
          }`,
          opacity: inactive ? '0.5' : 'undefined',
        })}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            justifyContent: 'flex-start',
          }}
        >
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <P variant="body-bold">{t(titleKey)}</P>
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <P variant="h2">{`${invitedCountDFC} / ${inviteesCount}`}</P>
          </Box>
          {!pastExperience && evaluatorsOutstanding > 0 && (
            <>
              <Grid container alignItems="flex-start" direction="column">
                <StyledP variant="body-bold">
                  {!isEventTypeDFC
                    ? t(
                        'ui.event-management.events.overview.learners.evaluators.outstanding',
                        { count: evaluatorsOutstanding },
                      )
                    : ''}
                </StyledP>
                <P>
                  {eventReminder?.reminderAt
                    ? t('ui.event-management.events.learners.reminder-set', {
                        reminderAt: formatDate(eventReminder?.reminderAt),
                      })
                    : t('ui.event-management.events.learners.reminder-not-set')}
                </P>
                {immediateReminderSent ? (
                  <P>
                    {t(
                      'ui.event-management.events.learners.reminder-last-sent',
                      {
                        date: formatDate(new Date().toISOString()),
                      },
                    )}
                  </P>
                ) : null}
              </Grid>
              <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
              <Button
                onClick={() => setReminderDialogOpen(true)}
                fullWidth
                variant="contained"
                color="primary"
                disabled={isDisabled}
              >
                {eventReminder?.reminderAt
                  ? t(
                      'ui.event-management.events.learners.button.change-reminder',
                    )
                  : t(
                      'ui.event-management.events.learners.button.set-reminder',
                    )}
              </Button>
              <Button
                onClick={() => handleSetImmediateReminder()}
                fullWidth
                variant="outlined"
                color="primary"
                disabled={isDisabled || isPending || immediateReminderSent}
                sx={{ mt: 1 }}
              >
                {t(
                  'ui.event-management.events.learners.button.send-reminder-now',
                )}
              </Button>
            </>
          )}
        </CardContent>
      </Card>
      <SetReminderDialog
        event={event}
        open={reminderDialogOpen}
        onClose={() => setReminderDialogOpen(false)}
        onSetReminder={handleSetReminder}
        onCancelReminder={handleCancelReminder}
        reminderAt={
          eventReminder?.reminderAt
            ? DateTime.fromISO(eventReminder?.reminderAt, { zone: 'UTC' })
            : null
        }
      />
    </Badge>
  );
};

export default EvaluatorRemindersCard;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
