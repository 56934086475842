import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Box, Button, Link, styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import {
  ColourEnergyIndicator,
  P,
  Span,
} from '@insights-ltd/design-library/components';
import { formatDate } from '@insights-ltd/design-library/utils';
import {
  EventResponseWithChapters,
  InviteeResponse,
  useEmailEventProfiles,
} from 'api';
import { isIdtlExperience, isExploreExperience } from 'domain/event';
import EmailProgressDialog from 'components/EmailProgressDialog';
import LeadingColourEnergyIndicator from 'components/LeadingColourEnergyIndicator';
import DistributionMethodDialog from 'components/DistributionMethodDialog';
import { CHAPTERS } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type LearnersListRowProps = {
  invitee: InviteeResponse;
  deleteInvitee: (inviteeId: string) => void;
  resendInvite?: (inviteeId: string) => void;
  isLastRow: boolean;
  isPastExperience: boolean;
  event: EventResponseWithChapters;
  readyToPurchase: boolean;
  readyToSend: boolean;
};

const StyledP = styled(P)({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const LearnersListRow = ({
  deleteInvitee,
  resendInvite,
  invitee,
  isLastRow,
  isPastExperience,
  event,
  readyToPurchase,
  readyToSend,
}: LearnersListRowProps) => {
  const { t } = useTranslation();
  const handleDeleteInvites = () => deleteInvitee(invitee.id);
  const [sendingProfile, setSendingProfile] = useState(false);
  const [showChoosingDistDialog, setShowChoosingDistDialog] = useState(false);
  const { mutate: emailProfiles } = useEmailEventProfiles();
  const isExplore = isExploreExperience(event.eventType);
  const isExploreAndProfileGenerated = isExplore && readyToSend;

  const chapters =
    invitee.chapters
      .map((chapter) => CHAPTERS.find((el) => el.id === chapter.name)?.titleKey)
      .map((chapterKey) => chapterKey && t(chapterKey))
      .join(', ') || '';

  const handleSendEmail = (format?: string) => {
    setShowChoosingDistDialog(false);
    setSendingProfile(true);
    emailProfiles({
      eventId: event.id,
      inviteeIds: [invitee.id],
      dialect: invitee.dialect,
      generateUsing: 'EVALUATION_DIALECT',
      format,
    });
  };

  const shareProfile = () => {
    if (isExplore) {
      handleSendEmail('share-digital-profile-link');
    } else {
      setShowChoosingDistDialog(true);
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="top"
        justifyContent="space-between"
        spacing={1}
        data-testid="learners-list-row"
        sx={(th) => ({
          padding: `${th.spacing(spacingSizeMap.S)} ${th.spacing(
            spacingSizeMap.M,
          )}`,
          color: th.palette.grey[600],
          wordBreak: 'break-word',
        })}
      >
        <Grid item xs={3}>
          <Box display="flex" alignItems="center">
            {!isExplore && (
              <LeadingColourEnergyIndicator
                leadingColourEnergy={invitee.leadingColourEnergy}
              />
            )}
            <P>{invitee.fullName}</P>
          </Box>
          <P>
            <Link
              target="_blank"
              href={`mailto:${invitee.email}`}
              rel="noopener noreferrer"
            >
              {invitee.email}
            </Link>
          </P>
        </Grid>
        {!isIdtlExperience(event.eventType) && (
          <Grid item xs={4}>
            <P>{chapters}</P>
            {['REUSED', 'AVAILABLE_FOR_REUSE'].includes(
              invitee.profile?.status || '',
            ) && (
              <StyledP>
                <CheckCircleIcon htmlColor="#0d6c51" /> &nbsp;
                {t('ui.event-management.invite-list.reused-profile.label')}
              </StyledP>
            )}
            {invitee.scores && isExplore && (
              <Box data-testid="colour-scores" sx={{ maxWidth: '330px' }}>
                <ColourEnergyIndicator
                  blue={invitee.scores.blue}
                  green={invitee.scores.green}
                  yellow={invitee.scores.yellow}
                  red={invitee.scores.red}
                  preferred={invitee.scores.preferred}
                />
              </Box>
            )}
          </Grid>
        )}
        <Grid
          container
          justifyContent="flex-start"
          direction="column"
          item
          xs={3}
          sx={{ textAlign: 'right' }}
        >
          {!isPastExperience && resendInvite && (
            <Grid item>
              <Button onClick={() => resendInvite(invitee.id)}>
                <Span variant="body-bold" color="primary">
                  {t('ui.event-management.invite-list.resend-invite')}
                </Span>
              </Button>
            </Grid>
          )}
          {!isPastExperience && resendInvite && invitee?.lastInviteAt && (
            <Grid item>
              <P>
                {t('ui.event-management.invite-list.last-sent', {
                  lastInviteAt: formatDate(invitee?.lastInviteAt || ''),
                })}
              </P>
            </Grid>
          )}
          {readyToPurchase && invitee?.evaluation?.submittedOn && (
            <Grid item>
              <P>
                {t('ui.event-management.invite-list.completed-on', {
                  completedAt: formatDate(invitee.evaluation.submittedOn),
                })}
              </P>
            </Grid>
          )}
          {invitee?.profileLastSentAt ? (
            <Grid item>
              <Button onClick={shareProfile} sx={{ padding: 0 }}>
                <P variant="body-bold" color="primary">
                  {t('ui.event-management.invite-list.share-profile')}
                </P>
              </Button>
              <P>
                {t('ui.event-management.invite-list.profile-last-shared', {
                  profileLastSentAt: formatDate(
                    invitee?.profileLastSentAt || '',
                  ),
                })}
              </P>
            </Grid>
          ) : (
            readyToSend && (
              <Grid item>
                <Button onClick={shareProfile} sx={{ padding: 0 }}>
                  <P variant="body-bold" color="primary">
                    {t('ui.event-management.invite-list.share-profile')}
                  </P>
                </Button>
              </Grid>
            )
          )}
        </Grid>
        {!isPastExperience && !isExploreAndProfileGenerated && (
          <Grid
            item
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            xs={1}
          >
            <Button
              sx={{ padding: 0, minWidth: 0 }}
              onClick={handleDeleteInvites}
              aria-label={t(
                'ui.event-management.events.invitee.confirm-delete.title',
              )}
            >
              <P variant="body-bold" color="error">
                {t('ui.event-management.invite-list.remove-user')}
              </P>
            </Button>
          </Grid>
        )}
      </Grid>
      {!isLastRow && <Divider variant="fullWidth" />}
      <DistributionMethodDialog
        open={showChoosingDistDialog}
        profileCount={1}
        onSendViaLink={() => handleSendEmail('link')}
        onSendViaEmail={() => handleSendEmail('attachment')}
        onCancel={() => setShowChoosingDistDialog(false)}
      />
      <EmailProgressDialog
        singleProfile
        open={sendingProfile}
        onClose={() => setSendingProfile(false)}
      />
    </>
  );
};

export default LearnersListRow;
