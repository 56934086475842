/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { H1, P, Span } from '@insights-ltd/design-library/components';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import downloadTransactionHistory from 'pages/Transactions/downloadTransactionHistory';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { isMultiOrg } from 'utils/isMultiOrg';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';
import {
  useGetAllOrganisations,
  useGetMyLatestTransactions,
  useGetOrganisation,
  useGetPractitionerById,
} from 'api';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import TransactionsTable from './TransactionsTable';

const Transactions = () => {
  const { t } = useTranslation();
  const { practitionerId, organisationId } = useParams<{
    organisationId?: string;
    practitionerId: string;
  }>();
  const { data: practitioner } = useGetPractitionerById(practitionerId);
  const myLatestTransactionsQuery = useGetMyLatestTransactions(
    practitionerId || '',
    30,
  );
  const { data: transactions } = myLatestTransactionsQuery;

  const organisationQuery = useGetOrganisation(organisationId);
  const { data: organisation } = organisationQuery;
  const allOrganisationsQuery = useGetAllOrganisations();
  const { data: practitionerOrganisations } = allOrganisationsQuery;
  const isMultiOrgView = isMultiOrg(practitionerOrganisations);
  const status = queryStatusIgnoreIdle(
    myLatestTransactionsQuery,
    organisationQuery,
    allOrganisationsQuery,
  );

  const { openErrorModal } = useRequestErrorContext();

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.my-account.error.load')}
      />
    );
  }
  const myTransactions = !organisationId;

  const handleClick = () =>
    downloadTransactionHistory(practitionerId || '').catch(() =>
      openErrorModal(),
    );

  const crumbs: { [key: string]: string } = myTransactions
    ? {
        '/': t('ui.event-management.dashboard.home.title'),
        '/account': t('ui.event-management.my-account.title'),
      }
    : {
        '/': t('ui.event-management.dashboard.home.title'),
        '/organisations': t('ui.event-management.organisations.title'),
        [`/organisations/${organisation?.id}`]: organisation?.name || '',
        [`/organisations/${organisation?.id}/practitioners/${practitioner?.id}`]:
          practitioner?.fullName || '',
      };

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.transactions.title')}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={crumbs}
              activeText={t('ui.event-management.transactions.title')}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid container wrap="nowrap" item xs={12} md={6}>
              <H1 variant="h2">
                {myTransactions ? (
                  t('ui.event-management.my-transactions.header')
                ) : (
                  <Trans
                    i18nKey="ui.event-management.transactions.header"
                    components={{ bold: <Span variant="body-bold" /> }}
                    values={{ fullName: practitioner?.fullName || '' }}
                  />
                )}
              </H1>
            </Grid>
          </Grid>
          <Box
            mt={(theme) => theme.spacing(spacingSizeMap.M)}
            mb={(theme) => theme.spacing(spacingSizeMap.L)}
          >
            <Divider />
          </Box>
          {transactions!.length ? (
            <>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <P color="textSecondary">
                    {t('ui.event-management.my-account.transactions.helper', {
                      count: transactions!.length,
                    })}
                  </P>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={handleClick}
                    aria-label="ui.event-management.my-account.transactions.export_transactions"
                  >
                    {t(
                      'ui.event-management.my-account.transactions.export_transactions',
                      { days: 90 },
                    )}
                  </Button>
                </Grid>
              </Grid>

              <Box mb={(theme) => theme.spacing(spacingSizeMap.M)} />
              <TransactionsTable
                transactions={transactions!}
                isMultiOrgView={isMultiOrgView}
              />
            </>
          ) : (
            <P>{t('ui.event-management.my-account.transactions.empty')}</P>
          )}
        </Container>
      </FullHeight>
    </>
  );
};

export default Transactions;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
