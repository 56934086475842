import { queryStatus } from 'utils/queryStatus';
import { useGetEventTypes } from 'components/hooks/useGetEventTypes';
import { useGetPractitioner } from 'api/practitioners/practitionerHooks';
import { useGetAllOrganisations } from 'api/organisations/organisationHooks';
import { OrganisationResponse } from 'api';
import { EventTypes } from 'types/types';

const useGetCoreDetailsData = (
  isCreating: boolean,
): {
  setMyOrganisation: boolean;
  isPermitted: boolean;
  isPending: boolean;
  isError: boolean;
  organisations?: OrganisationResponse[];
  myOrganisation?: OrganisationResponse;
  showOrganisationDropdown: boolean;
  eventTypes: Partial<EventTypes>;
} => {
  const { status: getPractitionerStatus, data: practitioner } =
    useGetPractitioner();

  const { data: organisationsData, status: getAllOrganisationsStatus } =
    useGetAllOrganisations();

  const responseStatus = queryStatus(
    getAllOrganisationsStatus,
    getPractitionerStatus,
  );
  const hasMultiOrgs =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getAllOrganisationsStatus === 'success' && organisationsData!.length > 1;
  const showOrganisationDropdown = hasMultiOrgs && isCreating;

  const setMyOrganisation = !hasMultiOrgs && isCreating;

  const organisations = organisationsData?.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return {
    setMyOrganisation,
    isPermitted: hasMultiOrgs,
    isPending: responseStatus === 'pending',
    isError: responseStatus === 'error',
    organisations,
    myOrganisation: organisations?.[0],
    showOrganisationDropdown,
    eventTypes: useGetEventTypes(practitioner),
  };
};

export default useGetCoreDetailsData;
