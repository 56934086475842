import { useGetEvaluatorLink, useGetLearnerByOrganisation } from 'api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import React from 'react';
import { queryStatus } from 'utils/queryStatus';
import EditLearner from './EditLearner';

const EditEvaluatorLinkLearner = () => {
  const { evaluatorLinkId, organisationId, learnerId } = useParams<{
    evaluatorLinkId: string;
    learnerId: string;
    organisationId: string;
  }>();

  const { data, status: evalStatus } = useGetEvaluatorLink(
    evaluatorLinkId || '',
  );
  const { status: learnerStatus, data: learner } = useGetLearnerByOrganisation(
    learnerId || '',
    organisationId || '',
  );
  const status = queryStatus(evalStatus, learnerStatus);

  const { t } = useTranslation();
  const evaluationLinkCrumbs: Record<string, string> = {
    '/': t('ui.event-management.dashboard.home.title'),
    '/evaluator-links': t('ui.event-management.evaluator-links.title'),
    [`/evaluator-links/${evaluatorLinkId}`]:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      data === undefined ? '' : data!.name,
    [`/evaluator-links/${evaluatorLinkId}/${organisationId}/${learnerId}`]:
      learner?.fullName ?? '',
  };
  return <EditLearner otherStatus={status} crumbs={evaluationLinkCrumbs} />;
};
export default EditEvaluatorLinkLearner;
