import { TextV2 } from '@insights-ltd/design-library/components';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { isoToDuration } from 'utils/dates';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const DataRetentionPolicy = ({
  dataRetentionPolicy,
}: {
  dataRetentionPolicy?: { maximumInactivityPeriod?: string };
}) => {
  const theme = useTheme();

  if (!dataRetentionPolicy?.maximumInactivityPeriod) {
    return null;
  }

  const { years } = isoToDuration(dataRetentionPolicy.maximumInactivityPeriod);

  return (
    <Box sx={{ paddingTop: theme.spacing(spacingSizeMap.S) }}>
      <Trans
        i18nKey="ui.event-management.organisation.data-retention-period"
        components={{
          light: (
            <TextV2 color="textSecondary" variant="bodyBold" component="span" />
          ),
        }}
        values={{ years }}
      />
    </Box>
  );
};

export default DataRetentionPolicy;
