/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { TeamResponse } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import DeleteTeamDialog from './DeleteTeamDialog';
import ErrorDialog from './ErrorDialog';

type ActionsProps = {
  team: TeamResponse | undefined;
  isDeleting: boolean;
  onDelete: (onError: () => void, onSettled: () => void) => void;
  reset: () => void;
};

const Actions = ({ team, isDeleting, onDelete, reset }: ActionsProps) => {
  const { t } = useTranslation();

  const [deleteDialogOpen, setOpenDeleteDialog] = useState(false);
  const [errorDialogOpen, setOpenErrorDialog] = useState(false);

  const handleConfirmError = () => {
    reset();
    setOpenErrorDialog(false);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md="auto"
        sx={(theme) => ({
          '& > *': {
            marginLeft: `${theme.spacing(spacingSizeMap.XS)} !important`,
          },
        })}
      >
        <Button
          component={RouterLink}
          to={`/teams/${team?.id}/download-team-wheel`}
          variant="contained"
          color="primary"
        >
          {t('ui.event-management.teams.download-team-wheel')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          to={`/teams/${team?.id}/edit`}
          component={RouterLink}
          aria-label={t('ui.event-management.teams.edit')}
          replace
        >
          {t('ui.event-management.teams.edit')}
        </Button>
        <Button
          onClick={() => setOpenDeleteDialog(true)}
          sx={(theme) => ({
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            border: '1px solid',
            backgroundColor: 'transparent',
            '&:hover': {
              borderColor: theme.palette.error.main,
              backgroundColor: `${theme.palette.error.main}16`,
            },
          })}
          variant="outlined"
          aria-label={t('ui.event-management.teams.delete')}
        >
          {t('ui.event-management.teams.delete')}
        </Button>
      </Grid>
      <DeleteTeamDialog
        team={team!}
        open={deleteDialogOpen}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirmDelete={() =>
          onDelete(
            () => setOpenErrorDialog(true),
            () => setOpenDeleteDialog(false),
          )
        }
        deleting={isDeleting}
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleConfirmError}
        title={t('ui.event-management.learners.teams.error-dialog.title')}
        body={t('ui.event-management.learners.teams.error-dialog.body', {
          teamName: team!.name,
        })}
      />
    </>
  );
};

export default Actions;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
