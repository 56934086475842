/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SortDirection, SortType } from 'types/types';
import { InitialledScore } from 'pages/DownloadTeamWheel/scoreUtils';
import { ColourScore } from 'api';
import { WheelState } from 'pages/DownloadTeamWheel/hooks/useWheelContext';

const useSorting = (
  scores: (InitialledScore & {
    organisationId: string;
    organisationName: string;
  })[],
  wheelState: WheelState,
) => {
  const { sortType, sortDirection, scoreType } = wheelState;

  const ascending = sortDirection === SortDirection.ASCENDING;
  const colour: ColourScore = ascending
    ? { blue: 4, green: 3, yellow: 2, red: 1 }
    : { blue: 1, green: 2, yellow: 3, red: 4 };
  type Key = keyof ColourScore;

  switch (sortType) {
    case SortType.NUMBER:
      return ascending
        ? scores.sort((a, b) => a.index - b.index)
        : scores.sort((a, b) => b.index - a.index);
    case SortType.NAME: {
      const direction = ascending ? 1 : -1;
      return [...scores].sort(
        (a, b) => direction * a.fullName.localeCompare(b.fullName),
      );
    }
    case SortType.WHEEL_POSITION: {
      return [...scores].sort((a, b) => {
        const fa: any =
          scoreType === 'CONSCIOUS'
            ? a.wheelPositions.conscious
            : a.wheelPositions.lessConscious;
        const fb =
          scoreType === 'CONSCIOUS'
            ? b.wheelPositions.conscious
            : b.wheelPositions.lessConscious;
        return ascending ? fa - fb : fb - fa;
      });
    }
    case SortType.COLOUR_PREF: {
      return scores
        .map((row) => {
          const baseScores: ColourScore =
            scoreType === 'CONSCIOUS'
              ? row.colourScores.conscious
              : row.colourScores.lessConscious;
          const sortScoreArrays = (Object.keys(colour) as Key[])
            .map((key) => [baseScores[key], colour[key]])
            .sort((a, b) => b[0] - a[0]);
          return { ...row, sortScoreArrays };
        })
        .sort((fa, fb) => {
          const a = fa.sortScoreArrays;
          const b = fb.sortScoreArrays;
          return (
            b![0][1] - a![0][1] ||
            b![1][1] - a![1][1] ||
            b![2][1] - a![2][1] ||
            b![3][1] - a![3][1]
          );
        });
    }
    case SortType.ORGANISATION: {
      const direction = ascending ? 1 : -1;
      return [...scores].sort((a, b) => {
        if (a.organisationName === b.organisationName) {
          return a.fullName.localeCompare(b.fullName);
        }
        return direction * a.organisationName.localeCompare(b.organisationName);
      });
    }
    default:
      return scores;
  }
};
export default useSorting;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
