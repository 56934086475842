/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { Alert, H1 } from '@insights-ltd/design-library/components';
import TeamForm from 'pages/EditTeam/EditTeamForm';
import { TeamData } from 'pages/EditTeam/types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  updateMultiOrgTeam,
  useAddTeamLearner,
  useGetTeam,
  useGetTeamLearners,
  useRemoveTeamLearner,
  updateSingleOrgTeam,
  TeamEditRequest,
  MultiOrgTeamEditRequest,
} from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import { useQueryClient } from '@tanstack/react-query';
import getOrganisationsDiff from 'utils/getOrganisationDiff';

const EditTeam = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId } = useParams<{ teamId: string }>();
  const queryClient = useQueryClient();
  const { mutateAsync: addLearner } = useAddTeamLearner(queryClient);
  const { mutateAsync: removeLearner } = useRemoveTeamLearner();
  const { status: teamStatus, data: team } = useGetTeam(teamId || '');
  const { status: teamLearnersStatus, data: teamLearners } = useGetTeamLearners(
    { teamId: teamId || '', searchTerm: '', purchaseStatus: 'ALL' },
  );

  const organisationId = team?.organisationContext?.organisations[0].id ?? '';

  const updateTeam =
    team?.organisationContext?.type === 'SINGLE'
      ? (payload: TeamEditRequest) =>
          updateSingleOrgTeam({
            ...payload,
            teamId: teamId ?? '',
            organisationId,
          })
      : updateMultiOrgTeam;

  const { openErrorModal } = useRequestErrorContext();

  const onSubmit = async ({
    learners,
    selectedOrgs = [],
    ...rawFormData
  }: TeamData) => {
    const newIds = learners.map(({ id }) => id);
    const oldIds = (teamLearners?.learners ?? []).map(({ id }) => id);

    const requestBody: MultiOrgTeamEditRequest = {
      teamId: teamId || '',
      formData: {
        ...rawFormData,
        addOrganisations: [],
        removeOrganisations: [],
      },
    };

    const { add, remove } = getOrganisationsDiff(
      team?.organisationContext?.organisations ?? [],
      selectedOrgs,
    );

    requestBody.formData.addOrganisations = add;
    requestBody.formData.removeOrganisations = remove;

    try {
      const response = await updateTeam(requestBody);

      await Promise.all([
        ...newIds.reduce<Promise<void>[]>(
          (acc, newId) =>
            !oldIds.includes(newId)
              ? [...acc, addLearner({ learnerId: newId, teamId: response.id })]
              : acc,
          [],
        ),
        ...oldIds.reduce<Promise<void>[]>(
          (acc, oldId) =>
            !newIds.includes(oldId)
              ? [
                  ...acc,
                  removeLearner({ learnerId: oldId, teamId: response.id }),
                ]
              : acc,
          [],
        ),
      ]);

      navigate(`/teams/${response.id}`);
    } catch (err) {
      openErrorModal();
    }
  };

  if (teamStatus === 'pending' || teamLearnersStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (teamStatus === 'error' || teamLearnersStatus === 'error') {
    return (
      <FullScreenError message={t('ui.event-management.teams.error-loading')} />
    );
  }
  return (
    <>
      <Helmet>
        <title>
          {t('ui.event-management.title.edit-team', {
            teamName: team!.name,
          })}
        </title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.events.nav.home'),
                '/learners': t('ui.event-management.learners-and-teams.title'),
                [`/teams/${teamId}`]: team!.name,
              }}
              activeText={t('ui.event-management.teams.edit')}
            />
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <H1 variant="h2">{t('ui.event-management.teams.edit')}</H1>
                </Grid>
              </Grid>
              <Box my={(theme) => theme.spacing(spacingSizeMap.L)} />
              <Divider />
              <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
              {team.organisationContext?.type === 'MULTIPLE' && (
                <Alert kind="warning">
                  {t('ui.event-management.learners.teams.multi-org-warning')}
                </Alert>
              )}
              <TeamForm
                team={team!}
                learners={teamLearners.learners}
                onSubmit={onSubmit}
              >
                <Grid container justifyContent="flex-end">
                  <Button
                    style={{ marginTop: '0' }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t('ui.event-management.teams.edit.edit-button')}
                  </Button>
                </Grid>
              </TeamForm>
            </Grid>
          </Grid>
        </Container>
      </FullHeight>
    </>
  );
};

export default EditTeam;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
