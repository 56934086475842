import { EventType, EventTypes } from 'types/types';
import { EVENT_TYPES } from 'variables';
import { PractitionerData, useGetCreateableEventTypes } from 'api';

export const useGetEventTypes = (
  practitioner?: PractitionerData,
): Partial<EventTypes> => {
  const { data: creatableExperienceTypes } = useGetCreateableEventTypes(
    practitioner?.id ?? '',
    {},
  );

  const { eventTypes } = creatableExperienceTypes || { eventTypes: [] };
  const experienceTypeMap = eventTypes.length
    ? Object.fromEntries(
        eventTypes.map((type: string) => [
          type,
          EVENT_TYPES[type as EventType],
        ]),
      )
    : {};
  return experienceTypeMap;
};

export default useGetEventTypes;
