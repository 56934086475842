import { LearnerContributors } from 'api/organisationGroups/organisationGroupsTypes';

export function awaitingResponseFilter(
  learnerContributors: LearnerContributors,
) {
  const statuses = learnerContributors.contributors.map(({ status }) => status);
  return (
    !statuses.includes('READY_TO_PURCHASE') &&
    !statuses.includes('PROFILE_GENERATED') &&
    learnerContributors.learner.status !== 'READY_TO_PURCHASE'
  );
}

export function readyToPurchaseFilter(
  learnerContributors: LearnerContributors,
) {
  const statuses = learnerContributors.contributors.map(({ status }) => status);
  const learnerProfileStatus =
    learnerContributors.learner.profile?.status === 'AVAILABLE_FOR_REUSE' ||
    !learnerContributors.learner.profile?.status;
  return (
    statuses.includes('READY_TO_PURCHASE') &&
    learnerContributors.learner.status !== 'PROFILE_GENERATED' &&
    learnerContributors.profileStatus !== 'PROFILE_GENERATED' &&
    learnerProfileStatus
  );
}

export function createdStatusFilter(learnerContributors: LearnerContributors) {
  return learnerContributors.learner.status === 'PROFILE_GENERATED';
}

export function profileCreatedFilter(learnerContributors: LearnerContributors) {
  return learnerContributors.profileStatus === 'PROFILE_GENERATED';
}
