import { useLocation } from 'react-router-dom';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';
import { User, useAuth } from 'contexts/AuthContext';
import {
  OrganisationResponse,
  PractitionerData,
  WalletResponse,
  useGetAllOrganisations,
  useGetOrganisation,
  useGetPractitionerById,
  useGetPractitionerWallet,
} from 'api';

export const useGetTransferUnits = ():
  | {
      isPending: boolean;
      isError: boolean;
      user?: undefined;
      userId?: undefined;
      practitioner?: undefined;
      practitionerId?: undefined;
      wallet?: undefined;
      organisation?: undefined;
      organisations?: undefined;
      hasMultiOrgs?: undefined;
      isMyAccount?: undefined;
    }
  | {
      isPending: false;
      isError: false;
      user?: User;
      userId: string;
      practitioner?: PractitionerData;
      practitionerId?: string;
      wallet?: WalletResponse;
      organisation?: OrganisationResponse;
      organisations?: OrganisationResponse[];
      hasMultiOrgs?: boolean;
      isMyAccount: boolean;
    } => {
  const { user } = useAuth();
  const { state } = useLocation();
  const locationState: any = state;

  const organisationQuery = useGetOrganisation(locationState?.organisationId);
  const { data: organisation } = organisationQuery;
  const practitionerQuery = useGetPractitionerById(
    locationState?.practitionerId,
  );
  const { data: practitioner } = practitionerQuery;
  const allOrganisationsQuery = useGetAllOrganisations();
  const { data: organisations } = allOrganisationsQuery;
  const { data: wallet, ...practitionerWalletQuery } = useGetPractitionerWallet(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    user!.id,
  );

  const loadingStatus = queryStatusIgnoreIdle(
    organisationQuery,
    allOrganisationsQuery,
    practitionerQuery,
    practitionerWalletQuery,
  );
  const isMyAccount = locationState?.practitionerId === undefined;
  const isPending = loadingStatus === 'pending';
  const isError = loadingStatus === 'error';
  const hasMultiOrgs = !!(organisations?.length && organisations?.length > 1);

  return isPending || isError
    ? { isPending, isError }
    : {
        isPending,
        isError,
        user,
        userId: user?.id || '',
        practitioner,
        practitionerId: locationState?.practitionerId,
        wallet,
        organisation,
        organisations,
        hasMultiOrgs,
        isMyAccount,
      };
};
