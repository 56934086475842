/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, Container, Grid, styled } from '@mui/material';
import {
  LearnerPurchaseRequest,
  OrganisationResponse,
  useDeleteEvaluatorLink,
  useGetEvaluatorLink,
  usePurchaseProfilesForEvaluatorLink,
} from 'api';
import Breadcrumbs from 'components/Breadcrumbs';
import FullScreenError from 'components/FullScreen/FullScreenError';
import FullScreenSpinner from 'components/FullScreen/FullScreenSpinner';
import LinkDisplay from 'components/LinkDisplay/LinkDisplay';
import { H1, P, Span } from '@insights-ltd/design-library/components';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PermissionGuard from 'components/PermissionGuard';
import AddToTeam from 'pages/AddToTeam/AddToTeam';
import { User } from 'types/types';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import { useQueryClient } from '@tanstack/react-query';
import PurchasePage from 'components/PurchasePage/PurchasePage';
import { Dialects } from 'types/dialects';
import { DIALECT_MAP } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { emptyLink } from 'mocks/mock-data/evaluatorLinks';
import EvaluatorResults from './EvaluatorResults/EvaluatorResults';
import LearnerSelectionBar from './LearnerSelectionBar/LearnerSelectionBar';
import DeleteCompletedEvaluatorsDialog from './DeleteCompletedEvaluatorsDialog';
import FullHeight from '../../components/FullHeight/FullHeight';

const StyledLink = styled(Link)(({ theme: t }) => ({
  color: t.palette.blue.main,
  fontWeight: t.typography.fontWeightBold,
  textDecoration: 'none',
  textDecorationColor: t.palette.blue.main,

  '&:visited': {
    color: t.palette.blue.main,
    textDecorationColor: t.palette.blue.main,
  },
}));

const StyledTitle = styled(H1)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(spacingSizeMap.XS),
  wordWrap: 'break-word',
}));

const OrganisationLink = ({
  organisation,
}: {
  organisation: OrganisationResponse;
}) => {
  return (
    <P color="textSecondary">
      <Trans
        i18nKey="ui.event-management.evaluator-links.completed-evaluators.organisation"
        values={{ name: organisation.name }}
        components={{
          orglink: <StyledLink to={`/organisations/${organisation.id}`} />,
        }}
      />
    </P>
  );
};

const LinkLanguage = ({ locale }: { locale: Dialects }) => {
  const { t } = useTranslation();
  return (
    <P color="textSecondary">
      <Trans
        i18nKey="ui.event-management.evaluator-links.completed-evaluators.language"
        values={{
          dialectName: t(
            DIALECT_MAP.find(({ value }) => value === locale)?.textKey ?? '',
          ),
        }}
        components={{
          string: <Span variant="body-bold" />,
        }}
      />
    </P>
  );
};

const CompletedEvaluators = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const isPurchaseRoute = pathname.includes('purchase-profiles');

  // data table state
  const [checkedItems, setCheckedItems] = useState<{ [id: string]: User }>({});

  // Search state
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  // Get eval call
  const { evaluatorLinkId } = useParams<{ evaluatorLinkId: string }>();
  const { mutate: deleteEvaluatorLink, isPending: isDeleting } =
    useDeleteEvaluatorLink();
  const {
    data: { name, organisation, dialect, link, evaluatorModel, id } = emptyLink,
    isPending,
    isError,
  } = useGetEvaluatorLink(evaluatorLinkId!, {
    enabled: !isDeleting,
  });
  const navigate = useNavigate();

  const [deleteDialogOpen, setOpenDeleteDialog] = useState(false);

  const { openErrorModal } = useRequestErrorContext();

  const parentLink = `/evaluator-links/${evaluatorLinkId}`;

  const removeCheckedItems = (removeItems: string[]) => {
    const prevCheckItems = { ...checkedItems };
    removeItems.forEach((itemId) => {
      delete prevCheckItems[itemId];
    });
    setCheckedItems(prevCheckItems);
    if (Object.keys(prevCheckItems).length === 0 && isPurchaseRoute) {
      navigate(parentLink);
    }
  };

  const evaluationIds = Object.keys(checkedItems);

  const queryClient = useQueryClient();

  const {
    mutate,
    error,
    isError: isPurchaseError,
    isPending: isPurchaseLoading,
    reset: resetMutate,
  } = usePurchaseProfilesForEvaluatorLink(queryClient);

  const pageTitle = t(
    'ui.event-management.learners.purchase-profiles.page-title',
  );

  const handlePurchase = (
    requestData: LearnerPurchaseRequest,
    onSuccess: () => void,
    onFailure: () => void,
  ) =>
    mutate(
      {
        evaluatorLinkId: evaluatorLinkId ?? '',
        organisationId: organisation.id,
        request: requestData,
      },
      {
        onSuccess: () => {
          onSuccess();
          removeCheckedItems(evaluationIds);
          navigate(`/evaluator-links/${evaluatorLinkId}`);
        },
        onError: () => {
          onFailure();
        },
      },
    );

  const routes = {
    'add-to-team': <AddToTeam learners={Object.values(checkedItems)} />,
  };

  const handleConfirmDelete = () => {
    deleteEvaluatorLink(evaluatorLinkId!, {
      onSuccess: () => {
        setOpenDeleteDialog(false);
        navigate('/evaluator-links');
      },
      onError: () => {
        setOpenDeleteDialog(false);
        openErrorModal();
      },
    });
  };

  if (isPending) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  if (isError) {
    return <FullScreenError message={t('ui.event-management.generic.error')} />;
  }

  const evaluationLinkCrumbs: Record<string, string> = {
    '/': t('ui.event-management.events.nav.home'),
    '/evaluator-links': t('ui.event-management.evaluator-links.title'),
    [parentLink]: name,
  };

  const dialectQueryString = dialect ? `&dialect=${dialect}` : '';

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <FullHeight
        sx={{
          backgroundColor: isPurchaseRoute ? 'white' : 'white | grey',
          paddingRight: '0 !important',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'calc(100% - 100px)',
            paddingRight: '0 !important',
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              marginBottom: !evaluationIds.length ? '0 !important' : '4rem',
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
              height: '100%',
            }}
          >
            <PurchasePage
              backgroundColor={isPurchaseRoute ? 'white' : 'grey'}
              additionalRoutes={routes}
              title={pageTitle}
              indexRoute={
                <>
                  <Container
                    maxWidth={false}
                    sx={(theme) => ({
                      maxWidth: '100%',
                      paddingBottom: '5rem',
                      backgroundColor: theme.palette.primary.light,
                    })}
                  >
                    <Container maxWidth="lg">
                      <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
                        <Breadcrumbs
                          crumbs={{
                            '/': t('ui.event-management.events.nav.home'),
                            '/evaluator-links': t(
                              'ui.event-management.evaluator-links.title',
                            ),
                          }}
                          activeText={name}
                        />
                      </Box>
                      <Box py={(theme) => theme.spacing(spacingSizeMap.S)}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} md={6}>
                            <StyledLink to="/evaluator-links">
                              {t(
                                'ui.event-management.evaluator-links.completed-evaluators.all-evaluators-links',
                              )}
                            </StyledLink>
                            <StyledTitle variant="h2">{name}</StyledTitle>
                          </Grid>
                          <PermissionGuard
                            requiredPermissions={[
                              {
                                action: 'Evaluator_Link_Delete',
                                scope: 'Local',
                              },
                            ]}
                          >
                            <Grid
                              item
                              xs={12}
                              md="auto"
                              sx={{ marginTop: 'auto' }}
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setOpenDeleteDialog(true)}
                              >
                                {t(
                                  'ui.event-management.evaluator-links.completed-evaluators.delete-evaluator.label',
                                )}
                              </Button>

                              <DeleteCompletedEvaluatorsDialog
                                open={deleteDialogOpen}
                                onClose={() => setOpenDeleteDialog(false)}
                                handleConfirmDelete={handleConfirmDelete}
                              />
                            </Grid>
                          </PermissionGuard>
                        </Grid>
                      </Box>
                      <Box mb={2}>
                        <OrganisationLink organisation={organisation} />
                        {dialect && <LinkLanguage locale={dialect} />}
                      </Box>
                      <Box>
                        <LinkDisplay
                          link={`${link}?model=${evaluatorModel}${dialectQueryString}`}
                        />
                      </Box>
                    </Container>
                  </Container>
                  <EvaluatorResults
                    id={id}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    searchTerm={searchInputValue}
                    setSearchInputValue={setSearchInputValue}
                    searchInputValue={searchInputValue}
                    organisationid={organisation.id}
                  />
                </>
              }
              onReset={resetMutate}
              evaluationIds={evaluationIds}
              organisationIds={[organisation.id]}
              onPurchase={handlePurchase}
              parentLink={parentLink}
              breadcrumbs={evaluationLinkCrumbs}
              error={error}
              isError={isPurchaseError}
              isPurchasing={isPurchaseLoading}
              removeCheckedItems={removeCheckedItems}
            />
          </Container>
          {checkedItems && !isPurchaseRoute ? (
            <LearnerSelectionBar
              learnerIds={[]}
              evaluationIds={evaluationIds}
            />
          ) : null}
        </Box>
      </FullHeight>
    </>
  );
};

export default CompletedEvaluators;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
