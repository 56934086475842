import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSsoRedirect } from 'api/sso/ssoHooks';
import { FullScreenSpinner, FullScreenError } from 'components/FullScreen';
import { useTranslation } from 'react-i18next';

export const SsoRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate, isError } = useSsoRedirect();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    if (!code || !state) {
      throw new Error('Missing required parameters for Sso Redirect');
    }

    mutate({ code, state }, { onSuccess: () => navigate('/') });
  }, [mutate, location, navigate]);

  if (isError) {
    return <FullScreenError message={t('ui.event-management.generic.error')} />;
  }

  return <FullScreenSpinner message={t('ui.event-management.loading')} />;
};
