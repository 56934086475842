import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import CoreDetailsIcon from '@mui/icons-material/Assignment';
import EventDetailsIcon from '@mui/icons-material/DateRange';
import LocationIcon from '@mui/icons-material/LocationOn';
import AccountIcon from '@mui/icons-material/Person';
import Alert from '@mui/material/Alert';
import { EventResponse, useCancelEvent, useDeleteEvent } from 'api';
import { H3, P, Span } from '@insights-ltd/design-library/components';
import { dateInPast } from '@insights-ltd/design-library/utils';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventType } from 'types/types';
import { textKeyForDialect } from 'utils/languageUtils';
import { isExploreExperience, isIdtlExperience } from 'domain/event';
import { EVENT_TYPES, TIMEZONE_OPTIONS } from 'variables';
import useHistoryState, {
  LocationStateProvider,
} from 'components/hooks/useHistoryState';
import { FREQUENCY_OPTIONS } from 'variables/constants';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import DeleteOrCancelEventDialog from './DeleteOrCancelEventDialog';
import EventDetail, { EventDetailGrid } from './EventDetail';
import { PhysicalDetails, RemoteDetails } from './EventDetailsLocation';
import { DeleteType } from './types';

type Props = {
  event: EventResponse;
  eventInviteesCount: number;
  eventRefundableUnits: number;
};

const StyledH3 = styled(H3)({
  textAlign: 'center',
});

const toLocaleString = (
  date: string,
  dialect = 'en-GB',
  options?: LocaleOptions & DateTimeFormatOptions,
): string =>
  DateTime.fromISO(date, {
    zone: 'UTC',
  })
    .setLocale(dialect)
    .toLocaleString(options);

const formatDate = (date: string, dialect = 'en-GB') =>
  toLocaleString(date, dialect, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

const formatDateTime = (date: string, dialect = 'en-GB') => {
  const time = toLocaleString(date, dialect, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).toLowerCase();
  const day = formatDate(date, dialect);
  return `${day} - ${time}`;
};

const eventTypeKey = (eventType: EventType) => {
  const selectedEventType = EVENT_TYPES[eventType];

  if (!selectedEventType) {
    throw new Error(`Event Type '${eventType}' not recognised`);
  }

  return selectedEventType.textKey;
};

const EventSummary = ({
  event,
  eventInviteesCount,
  eventRefundableUnits,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [historyState] = useHistoryState<string>('prev');
  const queryClient = useQueryClient();
  const {
    mutate: deleteEvent,
    isPending: deleteIsPending,
    isError: deleteIsError,
    reset: deleteReset,
  } = useDeleteEvent(queryClient);
  const {
    mutate: cancelEvent,
    isPending: cancelIsPending,
    isError: cancelIsError,
    reset: cancelReset,
  } = useCancelEvent(queryClient);
  const navigate = useNavigate();
  const [deleteDialogOpen, setOpenDeleteDialog] = useState(false);
  const isExplore = isExploreExperience(event.eventType);
  const isPastExperience: boolean = dateInPast(event.startsAt, event.timezone);
  const isCancelledExperience = event.eventStatus === 'CANCELLED';
  const isReadOnly = isPastExperience || isCancelledExperience;
  const [eventDeleteType, setEventDeleteType] = useState<DeleteType>('cancel');
  const [refundedPractitioner, setRefundedPractitioner] = useState(
    event.practitioners.length && isExplore ? event.practitioners[0].id : '',
  );
  const { setSearchState } = useSearchContext();

  function getTimezoneLabel(timezone: string): string {
    const timezoneOption = TIMEZONE_OPTIONS.find(
      (option) => option.value === timezone,
    );

    return timezoneOption ? t(timezoneOption.label) : timezone;
  }

  useEffect(() => {
    let deleteType: DeleteType = 'delete';

    if (isExplore) {
      // If event is Explore type and Invitees count > 0, there are units, and startDate is in past
      if (
        eventInviteesCount > 0 ||
        eventRefundableUnits > 0 ||
        isPastExperience
      ) {
        deleteType = 'cancel';
      }
      // If event is not Explore && Invitees count > 0
    } else if (eventInviteesCount > 0) {
      deleteType = 'cancel';
    }

    setEventDeleteType(deleteType);
  }, [isExplore, eventInviteesCount, eventRefundableUnits, isPastExperience]);

  const frequency = FREQUENCY_OPTIONS.find(
    (option) => option.value === event.notificationFrequency,
  );

  const eventActionState =
    eventDeleteType === 'cancel'
      ? {
          isPending: cancelIsPending,
          isError: cancelIsError,
          reset: cancelReset,
        }
      : {
          isPending: deleteIsPending,
          isError: deleteIsError,
          reset: deleteReset,
        };

  const handleConfirmSuccess = () => {
    if (historyState?.includes('/organisations/')) {
      navigate(`/organisations/${event?.organisation?.id}`, {
        state: {
          activeTab: 'cancelled',
          showDeletedDialog: eventDeleteType,
          experienceName: event.name,
        },
      });
    } else {
      navigate(`/experiences`, {
        state: {
          showDeletedDialog: eventDeleteType,
          experienceName: event.name,
        },
      });
    }
  };

  const handleConfirmDelete = (type: DeleteType) => {
    if (type === 'cancel') {
      cancelEvent(
        {
          eventId: event.id,
          refundedPractitioner,
        },
        {
          onSuccess: () => handleConfirmSuccess(),
        },
      );
    } else {
      deleteEvent(
        {
          eventId: event.id,
        },
        {
          onSuccess: () => handleConfirmSuccess(),
        },
      );
    }

    if (type === 'cancel') {
      setSearchState({ searchTerm: '', category: 'cancelled' });
    }
  };

  return (
    <div>
      <StyledH3>{t('ui.event-management.events.summary.title')}</StyledH3>
      <EventDetail
        disabled={isReadOnly}
        editPath={`${pathname}/edit/core-details`}
        headerIcon={CoreDetailsIcon}
        headerTitle={t('ui.event-management.events.create.core-details')}
      >
        <EventDetailGrid
          details={[
            {
              title: t('ui.event-management.events.create.label.event-name'),
              value: event.name,
            },
            {
              title: t('ui.event-management.events.create.label.event-type'),
              value: t(eventTypeKey(event.eventType)),
            },
          ]}
        />
      </EventDetail>
      <EventDetail
        disabled={isReadOnly}
        editPath={`${pathname}/edit/practitioners`}
        headerIcon={AccountIcon}
        headerTitle={t(
          'ui.event-management.events.edit.practitioners.summary-header',
          { count: event.practitioners.length },
        )}
      >
        <Span>
          {event.practitioners.map((practitioner, index) => (
            <Span
              key={`personnel-${practitioner.id}`}
              variant="body-bold"
              color="textPrimary"
            >
              {practitioner.fullName}
              {index + 1 < event.practitioners.length ? ', ' : ''}
            </Span>
          ))}
        </Span>
      </EventDetail>
      <EventDetail
        disabled={isReadOnly}
        editPath={`${pathname}/edit/event-details`}
        headerIcon={EventDetailsIcon}
        headerTitle={t('ui.event-management.events.create.event-details')}
      >
        <EventDetailGrid
          details={[
            {
              title: t(
                'ui.event-management.events.create.label.event-language',
              ),
              value: t(textKeyForDialect(event.dialect)),
            },
            {
              title: t('ui.event-management.events.create.label.timezone'),
              value: getTimezoneLabel(event.timezone),
            },
            {
              title: t('ui.event-management.events.overview.label.starts'),
              value: formatDateTime(event.startsAt, i18n.language),
            },
            {
              title: t('ui.event-management.events.overview.label.ends'),
              value: formatDateTime(event.endsAt, i18n.language),
            },
            ...(!isIdtlExperience(event.eventType)
              ? [
                  {
                    title: t(
                      'ui.event-management.events.create.label.evaluator-deadline',
                    ),
                    value: formatDate(event.deadlineAt, i18n.language),
                  },
                  {
                    title: t(
                      'ui.event-management.events.create.label.email-updates',
                    ),
                    value: frequency?.textKey ? t(frequency.textKey) : '',
                  },
                  ...(isExplore && event.customEmailMessage
                    ? [
                        {
                          title: t(
                            'ui.event-management.events.create.label.custom-email',
                          ),
                          value:
                            event.customEmailMessage.length > 30
                              ? `${event.customEmailMessage.substring(
                                  0,
                                  30,
                                )}...`
                              : event.customEmailMessage,
                        },
                      ]
                    : []),
                ]
              : []),
          ]}
        />
      </EventDetail>
      <EventDetail
        disabled={isReadOnly}
        editPath={`${pathname}/edit/event-location`}
        headerIcon={LocationIcon}
        headerTitle={t(
          'ui.event-management.events.overview.label.event-location',
        )}
      >
        <Grid item container>
          <Grid item xs={6}>
            <P variant="body-bold" color="textSecondary">
              {t('ui.event-management.events.create.location')}
            </P>
          </Grid>
          <Grid item xs={6}>
            {event.location.type === 'REMOTE' ? (
              <RemoteDetails location={event.location} />
            ) : (
              <PhysicalDetails location={event.location} />
            )}
          </Grid>
        </Grid>
      </EventDetail>
      {!isReadOnly && (
        <Button
          fullWidth
          onClick={() => setOpenDeleteDialog(true)}
          sx={(theme) => ({
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            border: '1px solid',
            backgroundColor: 'transparent',
            marginTop: theme.spacing(spacingSizeMap.L),
            '&:hover': {
              borderColor: theme.palette.error.main,
              backgroundColor: `${theme.palette.error.main}16`,
            },
          })}
          variant="outlined"
        >
          {eventDeleteType === 'cancel'
            ? t('ui.event-management.events.overview.button.cancel-event')
            : t('ui.event-management.events.overview.button.delete-event')}
        </Button>
      )}
      <DeleteOrCancelEventDialog
        open={deleteDialogOpen}
        onClose={() => setOpenDeleteDialog(false)}
        handleConfirmDelete={() => handleConfirmDelete(eventDeleteType)}
        deleteType={eventDeleteType}
        isLoading={eventActionState.isPending}
        event={event}
        availableUnits={eventRefundableUnits}
        setRefundedPractitioner={setRefundedPractitioner}
      />
      <Snackbar
        open={eventActionState.isError}
        autoHideDuration={6000}
        onClose={eventActionState.reset}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={eventActionState.reset}
          severity="error"
        >
          {eventDeleteType === 'cancel'
            ? t('ui.event-management.events.error-canceling-event')
            : t('ui.event-management.events.error-deleting-event')}
        </Alert>
      </Snackbar>
    </div>
  );
};

const WrappedEventSummary = ({
  event,
  eventInviteesCount,
  eventRefundableUnits,
}: Props) => (
  <LocationStateProvider>
    <EventSummary
      event={event}
      eventInviteesCount={eventInviteesCount}
      eventRefundableUnits={eventRefundableUnits}
    />
  </LocationStateProvider>
);

export default WrappedEventSummary;
