import { authenticatedFetch } from 'api/fetch';
import { ArchiveProfileData } from 'api/archive/archiveApiTypes';

const baseUrl = '/api/v1/archives';

export const archiveProfile = async ({
  organisationId,
  profileId,
  archiveReason,
  reasonOther,
  betaAvailable,
}: ArchiveProfileData): Promise<void> => {
  const url = `${baseUrl}/${organisationId}`;

  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'insights-beta': `${betaAvailable}`,
    },
    body: JSON.stringify({ profileId, archiveReason, reasonOther }),
  });

  await json();
};

export const restoreProfile = async ({
  organisationId,
  profileId,
  betaAvailable,
}: ArchiveProfileData): Promise<void> => {
  const url = `${baseUrl}/${organisationId}`;

  const { json } = await authenticatedFetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'insights-beta': `${betaAvailable}`,
    },
    body: JSON.stringify({ profileId }),
  });

  await json();
};
