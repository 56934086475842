import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  InviteePayload,
  InviteeResponse,
  ProfileReuseStatus,
  useDeleteInvitee,
  useUpdateInvitee,
} from 'api';
import { H2, P } from '@insights-ltd/design-library/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import DeleteLearnerDialog from 'pages/EventLearners/DeleteLearnerDialog';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import ActionRequiredStatusList from './ActionRequiredStatusList';

type ActionRequiredListProps = {
  invitees: InviteeResponse[];
  eventId: string;
  isPastExperience: boolean;
  eventTimezone: string;
  eventDialect: string;
};

const ActionRequiredList = ({
  invitees,
  eventId,
  isPastExperience,
  eventTimezone,
  eventDialect,
}: ActionRequiredListProps) => {
  const { t } = useTranslation();
  const queryClient: QueryClient = useQueryClient();
  const { mutate: updateInvitee, isError: updateIsError } =
    useUpdateInvitee(queryClient);
  const {
    mutate: deleteInvitee,
    isPending: deleteIsPending,
    isError: deleteIsError,
    reset: deleteReset,
  } = useDeleteInvitee();
  const [inviteeId, setInviteeId] = useState<string>('');
  const inviteesCount: number = invitees.length;

  const handleUpdateInvitee = async (
    profileReusePreference: ProfileReuseStatus,
    invitee: InviteeResponse,
  ) => {
    const updatedInvitee: InviteePayload = {
      ...invitee,
      profileReusePreference,
    };
    updateInvitee({ eventId, invitee: updatedInvitee });
  };

  const handleDeleteInvitee = async (
    selectedInviteeId: string,
  ): Promise<void> => {
    setInviteeId(selectedInviteeId);
  };

  const handleConfirmDelete = () =>
    deleteInvitee(
      { eventId, inviteeId },
      { onSuccess: () => setInviteeId('') },
    );

  return (
    <>
      <H2 variant="h3">
        {t('ui.event-management.events.action-required-title', {
          count: inviteesCount,
        })}
      </H2>
      <Box mt={(theme) => theme.spacing(spacingSizeMap.S)} />
      <Grid container>
        <Grid item xs={12} md={8}>
          <P>{t('ui.event-management.events.action-required-description')}</P>
        </Grid>
      </Grid>
      <Box mt={(theme) => theme.spacing(spacingSizeMap.S)} />
      <Grid
        container
        sx={(theme) => ({
          padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
            spacingSizeMap.S,
          )}`,
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[300],
        })}
      >
        <Grid item xs={3}>
          <P variant="body-bold">
            {t('ui.event-management.events.list.heading.full-name-email')}
          </P>
        </Grid>
        <Grid item xs={4}>
          <P variant="body-bold">
            {t('ui.event-management.events.list.heading.profile-chapters')}
          </P>
        </Grid>
      </Grid>
      <Box mt={(theme) => theme.spacing(spacingSizeMap.S)} />
      <ActionRequiredStatusList
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        invitees={invitees!}
        updateInvitee={handleUpdateInvitee}
        deleteInvitee={handleDeleteInvitee}
        isPastExperience={isPastExperience}
        eventTimezone={eventTimezone}
        eventDialect={eventDialect}
      />
      <DeleteLearnerDialog
        open={Boolean(inviteeId)}
        onClose={() => setInviteeId('')}
        handleConfirmDelete={handleConfirmDelete}
        deleting={deleteIsPending}
      />
      <Snackbar
        open={deleteIsError}
        autoHideDuration={6000}
        onClose={deleteReset}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={deleteReset}
          severity="error"
        >
          {t('ui.event-management.events.invitee.error-deleting-invitee')}
        </Alert>
      </Snackbar>
      <Snackbar open={updateIsError} autoHideDuration={6000}>
        <Alert elevation={6} variant="filled" severity="error">
          {t('ui.event-management.events.invitee.error-updating-invitee')}
        </Alert>
      </Snackbar>
      <Box mt={(theme) => theme.spacing(spacingSizeMap.L)} />
    </>
  );
};

export default ActionRequiredList;
