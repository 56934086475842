import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Trans, useTranslation } from 'react-i18next';
import { P, Span } from '@insights-ltd/design-library/components';
import { UserPicker } from 'components/UserPicker';
import { useGetEventPractitioners } from 'api/practitioners/practitionerHooks';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { EventType } from 'types/types';
import { Practitioner, EventForm } from './types';

type Props = {
  event: EventForm;
  onSubmit: (practitioners: Practitioner[]) => void;
  children: React.ReactNode;
};

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const PractitionersForm = ({ event, onSubmit, children }: Props) => {
  const [practitioners, setPractitioners] = useState(event.practitioners);
  const {
    status,
    error,
    isError,
    data: availablePractitioners,
  } = useGetEventPractitioners(
    event?.organisation?.id || '',
    event.eventType as EventType,
    { enabled: !!event?.organisation },
  );

  const { t } = useTranslation();

  const submit = (submitEvent: React.FormEvent<HTMLFormElement>) => {
    submitEvent.preventDefault();
    onSubmit(practitioners);
  };
  return (
    <StyledForm noValidate autoComplete="off" onSubmit={(e) => submit(e)}>
      <P>
        <Trans
          t={t}
          i18nKey="ui.event-management.events.edit.practitioners.description.bold"
          components={{
            strong: <Span variant="body-bold" color="textSecondary" />,
          }}
        />
      </P>
      <UserPicker
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        users={availablePractitioners!}
        selectedUsers={practitioners}
        onChange={setPractitioners}
        loading={status === 'pending'}
        isLoadingError={isError}
      />
      {children}
      <Snackbar open={Boolean(error)} autoHideDuration={6000}>
        <Alert elevation={6} variant="filled" severity="error">
          {t('ui.event-management.events.edit.practitioners.request-error')}
        </Alert>
      </Snackbar>
    </StyledForm>
  );
};

export default PractitionersForm;
