import { InviteeListOptions, Contributor } from 'types/types';
import { InviteeResponse } from 'api';

export const readyToPurchase = (invitee: { status: InviteeListOptions }) =>
  invitee.status === 'READY_TO_PURCHASE';

export const profilesReadyToPurchased = (invitee: InviteeResponse) => {
  return invitee.status === 'READY_TO_PURCHASE' && !invitee.profile;
};

export const contributorProfilesReadyToPurchased = (contributor: Contributor) =>
  contributor.status === 'READY_TO_PURCHASE';

export const profilesPurchased = (invitee: InviteeResponse) => {
  return (
    invitee.profile?.status === 'PURCHASED' ||
    invitee.profile?.status === 'REUSED'
  );
};
