import { createBetaFlags } from '@insights-ltd/design-library/components';

export const BETA_DEFAULT = false;

const PRODUCTION_HOSTNAME = 'practitioner.insights.com';

export const getBetaAvailable = (): boolean => {
  return window.location.hostname !== PRODUCTION_HOSTNAME;
};

/*
When adding a new beta feature, add its name here.
When releasing a beta feature to production, remove it from here and TypeScript
will fail the build until every usage of it is removed.
 */
export type FEATURE =
  | 'default-3drs'
  | 'learner-search-performance'
  | 'supported-languages'
  | 'profile-archive'
  | 'schedule-share-feature';

export const { useBetaEnabled } = createBetaFlags<FEATURE>();
