/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { InviteeResponse, ProfileReuseStatus } from 'api';
import { P, Span } from '@insights-ltd/design-library/components';
import { formatDate } from '@insights-ltd/design-library/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@mui/material/ButtonBase';
import { CHAPTERS } from 'variables';
import { Link, styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type ActionRequiredListRowProps = {
  invitee: InviteeResponse;
  updateInvitee: (
    profileReusePreference: ProfileReuseStatus,
    invitee: InviteeResponse,
  ) => Promise<void>;
  deleteInvitee: (inviteeId: string) => void;
  isLastRow: boolean;
  isPastExperience: boolean;
  eventTimezone: string;
  eventDialect: string;
};

const ReuseContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
});

const StyledDecisionsButton = styled(P)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `0 ${theme.spacing(spacingSizeMap.XS)} 0 ${theme.spacing(
    spacingSizeMap.XS,
  )}`,
  fontWeight: theme.typography.fontWeightBold,
}));

const ActionRequiredListRow = ({
  updateInvitee,
  deleteInvitee,
  invitee,
  isLastRow,
  isPastExperience,
  eventTimezone,
  eventDialect,
}: ActionRequiredListRowProps) => {
  const { t } = useTranslation();
  const handleDeleteInvites = () => deleteInvitee(invitee.id);
  const chapters =
    invitee.chapters
      ?.map(
        (chapter) => CHAPTERS.find((el) => el.id === chapter.name)?.titleKey,
      )
      .map((chapterKey) => chapterKey && t(chapterKey))
      .join(', ') || '';

  const yesOnClick = () => updateInvitee('REQUEST', invitee);
  const noOnClick = () => updateInvitee('DECLINE', invitee);

  return (
    <>
      <Grid
        container
        alignItems="top"
        spacing={1}
        sx={(theme) => ({
          color: theme.palette.grey[700],
          wordBreak: 'break-word',
        })}
      >
        <Grid item xs={3}>
          <P>{invitee.fullName}</P>
          <P>
            <Link
              target="_blank"
              href={`mailto:${invitee.email}`}
              rel="noopener noreferrer"
            >
              {invitee.email}
            </Link>
          </P>
        </Grid>
        <Grid item xs={4}>
          <P>
            {t('ui.event-management.events.invitee.existing-profile', {
              profileDate: formatDate(
                invitee.profile!.createdAt!,
                eventTimezone,
                eventDialect,
              ),
            })}
          </P>
          <P>{chapters}</P>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'right',
          }}
        >
          <ReuseContainer>
            <P>
              {t('ui.event-management.events.invitee.profile-reuse-question')}
            </P>
            <ButtonBase
              disableTouchRipple
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                '&:hover, &:focus': {
                  textDecoration: 'underline',
                },
              })}
              onClick={yesOnClick}
            >
              <StyledDecisionsButton color="primary">
                {t('ui.event-management.events.invitee.profile-reuse-yes')}
              </StyledDecisionsButton>
            </ButtonBase>
            <Span>
              {t('ui.event-management.events.invitee.profile-reuse-slash')}
            </Span>
            <ButtonBase
              disableTouchRipple
              sx={(theme) => ({
                color: theme.palette.error.main,
                fontWeight: theme.typography.fontWeightBold,
                '&:hover, &:focus': {
                  textDecoration: 'underline',
                },
              })}
              onClick={noOnClick}
            >
              <StyledDecisionsButton color="error">
                {t('ui.event-management.events.invitee.profile-reuse-no')}
              </StyledDecisionsButton>
            </ButtonBase>
          </ReuseContainer>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          direction="row"
          item
          xs={2}
          sx={{ textAlign: 'right' }}
        >
          {!isPastExperience && (
            <Button
              onClick={handleDeleteInvites}
              aria-label={t(
                'ui.event-management.events.invitee.confirm-delete.title',
              )}
              sx={{ minWidth: 0, padding: 0, height: '1.75rem' }}
            >
              <P variant="body-bold" color="error">
                {t('ui.event-management.invite-list.remove-user')}
              </P>
            </Button>
          )}
        </Grid>
      </Grid>
      {!isLastRow && <Divider variant="fullWidth" />}
    </>
  );
};

export default ActionRequiredListRow;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
