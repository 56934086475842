/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { InviteeResponse, useEventInvitees, useGetEvent } from 'api';
import { H1, H2, P } from '@insights-ltd/design-library/components';
import { dateInPast, toLocaleString } from '@insights-ltd/design-library/utils';
import { queryStatus } from 'utils/queryStatus';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useIsPermitted from 'components/hooks/useIsPermitted';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import Notification from 'components/Notification';
import { isDFCExperience, isIdtlExperience } from 'domain/event';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { backButtonStyles } from 'components/layout/BreadcrumbLayout/BreadcrumbLayout';
import BackButton from 'components/BackButton/BackButton';
import ActionRequiredList from './ActionRequiredList/ActionRequiredList';
import AddLearnerForm from './AddLearnerForm';
import BulkUpload from './BulkUpload';
import LearnersList from './LearnersList';

const TabViewValue = {
  AddLearnersTab: 'LEARNERS',
  BulkUploadTab: 'UPLOAD',
};

const EventLearners = () => {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const [tabbedView, setTabbedView] = useState(TabViewValue.AddLearnersTab);
  const { status: eventStatus, data: event } = useGetEvent(eventId || '');
  const { status: inviteesStatus, data: invitees } = useEventInvitees(
    eventId || '',
  );
  const { status: permissionStatus, isPermitted: readExpiredIsPermitted } =
    useIsPermitted([
      {
        action: 'Experience_ReadExpired',
        scope: 'Local',
      },
    ]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const previousProfileInvitees: InviteeResponse[] = invitees?.filter(
    (invitee) =>
      invitee.profile?.status === 'AVAILABLE_FOR_REUSE' &&
      invitee.profile.reusePreference === 'NONE',
  )!;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const currentProfileInvitees: InviteeResponse[] = invitees?.filter(
    (invitee) =>
      invitee.profile?.status !== 'AVAILABLE_FOR_REUSE' ||
      invitee.profile.reusePreference !== 'NONE',
  )!;

  const responseStatus = queryStatus(
    eventStatus,
    inviteesStatus,
    permissionStatus,
  );

  if (responseStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (responseStatus === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.events.overview.error-loading-event')}
      />
    );
  }

  const isPastExperience: boolean = dateInPast(
    event!.startsAt,
    event!.timezone,
  );
  if (
    (isPastExperience && !readExpiredIsPermitted) ||
    isDFCExperience(event!.eventType)
  ) {
    return <Navigate to={`/experiences/${event!.id}`} />;
  }
  const expDateText = toLocaleString(
    event!.startsAt,
    event!.timezone,
    event!.dialect,
  );

  const parentLink = `/experiences/${eventId}`;

  return (
    <>
      <Helmet>
        <title>
          {t('ui.event-management.title.learners', { eventName: event!.name })}
        </title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.events.nav.home'),
                '/experiences': t('ui.event-management.events.title'),
                [`/experiences/${eventId}`]: t(event!.name),
              }}
              activeText={t(
                'ui.event-management.events.summary.manage-learners',
              )}
            />
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <BackButton
              parentLink={parentLink}
              i18nKey="ui.event-management.events.add-learners-and-contributors.learner.back-to-experience-overview"
              sx={backButtonStyles}
            />
            <H1 variant="h2">
              {t('ui.event-management.events.add-learners.title')}
            </H1>
          </Box>

          {isPastExperience ? (
            <>
              <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                <P color="error">
                  {t(
                    'ui.event-management.events.add-learners.read-only.sub-title',
                    {
                      expEndDate: expDateText,
                    },
                  )}
                </P>
              </Box>
              <LearnersList
                isPastExperience={isPastExperience}
                invitees={invitees!}
                event={event!}
              />
            </>
          ) : (
            <>
              {' '}
              <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                <P color="textSecondary">
                  {t('ui.event-management.events.add-learners.sub-title')}
                </P>
              </Box>
              {isIdtlExperience(event?.eventType || '') && (
                <Container sx={{ margin: '0', padding: '0' }} maxWidth="md">
                  <Notification
                    message={t(
                      'ui.event-management.events.learners.notification.get-idtl-discovery-purchase',
                    )}
                  />
                </Container>
              )}
              <TabContext value={tabbedView}>
                <TabList
                  onChange={(_, value) => setTabbedView(value)}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label={t(
                    'ui.event-management.events.add-learners.tab.label',
                  )}
                >
                  <Tab
                    label={t(
                      'ui.event-management.events.add-learners.tab.learners',
                    )}
                    value={TabViewValue.AddLearnersTab}
                  />
                  <Tab
                    label={t(
                      'ui.event-management.events.add-learners.tab.upload',
                    )}
                    value={TabViewValue.BulkUploadTab}
                  />
                </TabList>
                <TabPanel
                  value={TabViewValue.AddLearnersTab}
                  aria-label={t(
                    'ui.event-management.events.add-learners.tab.learners',
                  )}
                  sx={{ padding: '0' }}
                >
                  <Box mt={(theme) => theme.spacing(spacingSizeMap.L)} />
                  <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                    <H2 variant="h3">
                      {t('ui.event-management.events.add-learners.add-learner')}
                    </H2>
                  </Box>
                  <AddLearnerForm eventId={eventId || ''} />
                </TabPanel>
                <TabPanel
                  value={TabViewValue.BulkUploadTab}
                  aria-label={t(
                    'ui.event-management.events.add-learners.tab.upload',
                  )}
                  sx={{ padding: '0' }}
                >
                  <BulkUpload eventId={eventId || ''} />
                </TabPanel>
                {previousProfileInvitees.length > 0 ||
                currentProfileInvitees.length > 0 ? (
                  <Divider
                    sx={(theme) => ({
                      marginTop: theme.spacing(spacingSizeMap.L),
                      marginBottom: theme.spacing(spacingSizeMap.L),
                    })}
                  />
                ) : null}
                {previousProfileInvitees.length > 0 && (
                  <ActionRequiredList
                    isPastExperience={isPastExperience}
                    invitees={previousProfileInvitees}
                    eventId={eventId || ''}
                    eventTimezone={event!.timezone}
                    eventDialect={event!.dialect}
                  />
                )}
                <LearnersList
                  isPastExperience={isPastExperience}
                  invitees={currentProfileInvitees}
                  event={event!}
                />
              </TabContext>
            </>
          )}
        </Container>
      </FullHeight>
    </>
  );
};

export default EventLearners;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
