import React, { useState } from 'react';
import { CoreDetailsData, EventForm, FormData } from 'components/EventForms';
import { useAuth } from 'contexts/AuthContext';
import EventDetails from './EventDetails';
import Location from './Location';
import Practitioners from './Practitioners';
import CoreDetails from './CoreDetails';
import { usePromptBeforeUnload } from './usePromptBeforeUnload';

const STEPS = [CoreDetails, Practitioners, EventDetails, Location];

export const INITIAL_EVENT: EventForm = {
  name: '',
  productType: '',
  eventType: '',
  dialect: '',
  timezone: '',
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  deadlineAt: null,
  notificationFrequency: '',
  location: 'REMOTE',
  meetingLink: '',
  details: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  postcode: '',
  state: '',
  practitioners: [],
};

const development = import.meta.env.MODE === 'development';

const isCoreDetailsData = (formData: FormData): formData is CoreDetailsData =>
  'eventType' in formData;

const CreateEventForm = () => {
  const { user } = useAuth();
  const [event, setEvent] = useState<EventForm>({
    ...INITIAL_EVENT,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    practitioners: [user!],
  });
  const [step, setStep] = useState<number>(0);
  const [formComplete, setFormComplete] = useState<boolean>(false);

  const formStarted = event.name !== '';
  const showPrompt = !development && formStarted && !formComplete;

  usePromptBeforeUnload(showPrompt);

  const onBack = () => setStep((prevStep) => prevStep - 1);

  const onNext = () => setStep((prevStep) => prevStep + 1);

  const updateEvent = (newEvent: FormData) =>
    setEvent((oldEvent) => {
      if (
        isCoreDetailsData(newEvent) &&
        oldEvent.eventType !== newEvent.eventType
      ) {
        return {
          ...oldEvent,
          ...newEvent,
          dialect: INITIAL_EVENT.dialect,
          productType: INITIAL_EVENT.productType,
          deadlineAt: INITIAL_EVENT.deadlineAt,
        };
      }
      return {
        ...oldEvent,
        ...newEvent,
      };
    });

  const onFormComplete = () => setFormComplete(true);

  const CurrentFormStep = STEPS[step];

  return (
    <CurrentFormStep
      onNext={onNext}
      onBack={onBack}
      event={event}
      updateEvent={updateEvent}
      onFormComplete={onFormComplete}
    />
  );
};

export default CreateEventForm;
