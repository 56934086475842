/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EventForm, LocationData } from 'components/EventForms';
import { EventLocation, EventRequest, EventResponse } from 'api/httpEntities';
import { isIdtlExperience } from 'domain/event';
import { Frequency } from 'types/types';
import { DateTime } from 'luxon';

const toLocation = ({
  location,
  meetingLink,
  details,
  addressLine1,
  addressLine2,
  city,
  postcode,
  state,
  country,
}: EventForm): EventLocation =>
  location === 'REMOTE'
    ? {
        type: 'REMOTE',
        meetingLink,
        details,
      }
    : {
        type: 'PHYSICAL',
        addressLine1,
        addressLine2,
        city,
        postcode,
        state,
        country,
      };

export const dateWithTime = (date: DateTime, time: DateTime) => {
  const datePart = date.toISO()!.split('T')[0];
  const timePart = time.toISO()!.split('T')[1] || '00:00:00.000';
  return `${datePart}T${timePart.slice(0, 12)}Z`;
};

export const toEventRequest = (form: EventForm): EventRequest => {
  const {
    startDate,
    startTime,
    endDate,
    endTime,
    timezone,
    dialect,
    eventType,
    name,
    productType,
    practitioners,
    customEmailMessage,
  } = form;
  const startsAt = dateWithTime(startDate!, startTime!)!;
  const endsAt = dateWithTime(endDate!, endTime!)!;
  const deadlineAt = isIdtlExperience(eventType)
    ? undefined
    : dateWithTime(
        form.deadlineAt!,
        DateTime.fromISO('1970-01-01T23:59:59.999Z', { zone: 'UTC' }),
      )!;
  const notificationFrequency = isIdtlExperience(eventType)
    ? 'NEVER'
    : String(form.notificationFrequency);
  const location = toLocation(form);
  const practitionerIds = practitioners.map(({ id }) => ({ id }));
  return {
    dialect,
    eventType,
    name,
    productType: productType || 'DISCOVERY_PERSONAL_PROFILE',
    notificationFrequency,
    timezone,
    startsAt,
    endsAt,
    deadlineAt,
    location,
    practitioners: practitionerIds,
    customEmailMessage,
  };
};

const LOCATION_DEFAULTS: LocationData = {
  location: 'REMOTE',
  meetingLink: '',
  details: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postcode: '',
  country: '',
};

const responseToLocation = (location: EventLocation): LocationData => ({
  ...LOCATION_DEFAULTS,
  ...location,
  location: location.type,
});

export const eventResponseToForm = (event: EventResponse): EventForm => {
  const {
    startsAt,
    endsAt,
    deadlineAt,
    notificationFrequency: frequency,
    timezone,
    location,
    ...rest
  } = event;
  const notificationFrequency = frequency as Frequency;
  const locationData = responseToLocation(location);
  return {
    startDate: DateTime.fromISO(startsAt, { zone: 'UTC' }),
    startTime: DateTime.fromISO(startsAt, { zone: 'UTC' }),
    endDate: DateTime.fromISO(endsAt, { zone: 'UTC' }),
    endTime: DateTime.fromISO(endsAt, { zone: 'UTC' }),
    deadlineAt: DateTime.fromISO(deadlineAt, { zone: 'UTC' }),
    timezone,
    notificationFrequency,
    ...locationData,
    ...rest,
  };
};

export const getUniqueObjectsById = (objects: any[]) => {
  const uniqueObjects = [];
  const ids = new Set();
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of objects) {
    if (!ids.has(obj.id)) {
      ids.add(obj.id);
      uniqueObjects.push(obj);
    }
  }
  return uniqueObjects;
};

export const booleanizeCheckedItems = <ItemType extends Record<string, any>>(
  checkItems: ItemType,
): Record<string, boolean> => {
  return Object.keys(checkItems).reduce(
    (prev, value) => ({ ...prev, [value]: true }),
    {},
  );
};
/* eslint-enable @typescript-eslint/no-non-null-assertion */
