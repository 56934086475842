/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { H1, Text } from '@insights-ltd/design-library/components';
import { queryStatus } from 'utils/queryStatus';
import useIsPermitted from 'components/hooks/useIsPermitted';
import PermissionGuard from 'components/PermissionGuard';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  RequestError,
  useDeleteOrganisation,
  useGetAllGroups,
  useGetGroupByOrganisation,
  useGetOrganisation,
  useUpdateOrganisation,
} from 'api';
import OrganisationForm from 'components/OrganisationForm';
import { OrganisationData } from 'components/OrganisationForm/types';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { QueryStatus, useQueryClient } from '@tanstack/react-query';
import { DEFAULT_ROUTE } from 'variables';
import { useAuth } from 'contexts/AuthContext';
import SelectGroup from 'components/SelectGroup/SelectGroup';
import useTransferOrganisationToGroup from 'components/hooks/useTransferOrganisationToGroup';
import RequestErrorModal from 'components/RequestErrorDialog/RequestErrorModal';
import useSelectGroupState from 'components/SelectGroup/useSelectGroupState';
import { OrganisationResponse } from 'api/httpEntities';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';

const Label = () => (
  <Trans
    i18nKey="ui.event-management.organisations.add.select-group"
    components={{ styles: <Text color="textSecondary" /> }}
  />
);

const EditOrganisation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { organisationId } = useParams<{ organisationId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { status: orgStatus, data: organisation } =
    useGetOrganisation(organisationId);
  const {
    mutate: updateOrganisation,
    status: updateStatus,
    reset: resetUpdate,
  } = useUpdateOrganisation(organisationId || '', queryClient);
  const { openErrorModal, setOnClose } = useRequestErrorContext();

  const {
    mutate: deleteOrganisation,
    status: deleteStatus,
    reset: resetDelete,
  } = useDeleteOrganisation(queryClient);
  const { data: allGroups, status: groupStatus } = useGetAllGroups({
    enabled: user?.permissions.Organisation_Group_ReadAll === 'Global',
  });

  const {
    associatedGroup,
    groupError,
    setGroupError,
    selectedGroup,
    setSelectedGroup,
  } = useSelectGroupState(organisationId, allGroups);

  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

  const getGroupByOrganisationQuery = useGetGroupByOrganisation(
    organisationId || '',
    {
      enabled:
        user?.permissions.Organisation_Group_ReadAll &&
        user?.permissions.Organisation_Group_ReadAll !== 'None',
    },
  );
  const { data: organisationGroup } = getGroupByOrganisationQuery;

  const handleGroupError = (err: RequestError) => {
    setGroupError(err);
    if (err.status >= 500) {
      openErrorModal();
    }
  };

  const { mutateAsync: transferToGroup } = useTransferOrganisationToGroup(
    associatedGroup,
    organisationId,
    handleGroupError,
  );

  const { status: isAllowedToBeHereStatus, isPermitted: isAllowedToBeHere } =
    useIsPermitted([
      { action: 'Organisation_Update', resourceId: organisationId },
    ]);

  const loadingStatus = queryStatus(orgStatus as QueryStatus, groupStatus);

  useEffect(() => {
    if (isAllowedToBeHereStatus === 'success' && !isAllowedToBeHere) {
      navigate(DEFAULT_ROUTE);
    }
  }, [isAllowedToBeHereStatus, isAllowedToBeHere, navigate]);

  const isSaving = updateStatus === 'pending';
  const isDeleting = deleteStatus === 'pending';

  const onSuccess = (response: OrganisationResponse) => {
    if (selectedGroup || associatedGroup) {
      transferToGroup(selectedGroup, () => {
        navigate(`/organisations/${response!.id}`);
      }).then();
    } else {
      navigate(`/organisations/${response!.id}`);
    }
  };

  const onSubmit = async (
    formData: OrganisationData,
    onError: (error: RequestError) => void,
  ) => {
    updateOrganisation(
      {
        organisationId: organisationId || '',
        formData,
        groupId: organisationGroup?.id,
      },
      {
        onSuccess,
        onError: (error: RequestError) => {
          onError(error);
          if (error.status >= 500) {
            openErrorModal();
          }
        },
      },
    );
  };

  const onDeleteOrganisation = () =>
    deleteOrganisation(organisationId || '', {
      onSuccess: () => navigate('/organisations'),
    });

  const closeError = () => {
    if (deleteStatus === 'error') {
      setOnClose(resetDelete);
    } else {
      setOnClose(resetUpdate);
    }
    setErrorModalOpen(false);
  };

  const groupErrorText = groupError?.errorCodes?.includes(
    'ORGANISATION_ALREADY_IN_A_GROUP',
  )
    ? t(
        'ui.event-management.organisations.organisation-already-added-to-group-error',
        { organisationName: organisation?.name },
      )
    : null;

  if (loadingStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (loadingStatus === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisation.error-loading')}
      />
    );
  }

  const isPartnerGroup = organisationGroup?.type === 'PARTNER';

  const breadcrumbs: Record<string, string> = isPartnerGroup
    ? {
        '/': t('ui.event-management.events.nav.home'),
        '/organisations': t('ui.event-management.organisations.title'),
        [`/groups/${organisationGroup?.id}`]: t(
          'ui.event-management.group.breadcrumb.partner',
          {
            groupName: organisationGroup?.name,
          },
        ),
        [`/organisations/${organisationId}`]: organisation!.name,
      }
    : {
        '/': t('ui.event-management.dashboard.home.title'),
        [`/organisations/${organisationId}`]: organisation!.name,
      };

  const activeText = t('ui.event-management.organisations.edit.title');

  return (
    <>
      <Helmet>
        <title>
          {t('ui.event-management.title.edit-organisation', {
            organisationName: organisation!.name,
          })}
        </title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs crumbs={breadcrumbs} activeText={activeText} />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <H1 variant="h2">
                {t('ui.event-management.organisations.edit.title')}
              </H1>
            </Grid>
            <PermissionGuard
              requiredPermissions={[
                { action: 'Organisation_Delete', resourceId: organisation!.id },
              ]}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isDeleting || isSaving}
                  onClick={onDeleteOrganisation}
                  endIcon={
                    isDeleting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )
                  }
                >
                  {t('ui.event-management.organisations.edit.delete-button')}
                </Button>
              </Grid>
            </PermissionGuard>
          </Grid>
          <Box my={(theme) => theme.spacing(spacingSizeMap.L)} />
          <Divider />
          <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
          <Grid container>
            <Grid item xs={12} md={8}>
              <OrganisationForm
                organisation={organisation!}
                onSubmit={onSubmit}
                isGroupedOrg={!!organisationGroup}
                showWarning
              >
                <PermissionGuard
                  requiredPermissions={[
                    { action: 'Organisation_Group_Update', scope: 'Global' },
                  ]}
                >
                  <SelectGroup
                    disabled
                    selectedGroup={selectedGroup}
                    options={allGroups ?? []}
                    onSelect={(group) => {
                      setSelectedGroup(group);
                    }}
                    selectedLabel={<Label />}
                    fieldLabel={<Label />}
                    error={groupErrorText}
                  />
                </PermissionGuard>
                <Grid container justifyContent="space-between">
                  <div />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isDeleting || isSaving}
                    endIcon={
                      isSaving && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )
                    }
                  >
                    {t('ui.event-management.organisations.edit.edit-button')}
                  </Button>
                </Grid>
              </OrganisationForm>
            </Grid>
          </Grid>
        </Container>
      </FullHeight>

      <RequestErrorModal open={errorModalOpen} onClose={closeError} />
    </>
  );
};

export default EditOrganisation;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
