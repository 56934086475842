import { InviteeProfile, ProfileReuseStatus, ProfileStatus } from 'api';
import { ProfilesResponse } from 'api/organisations/organisationApitypes';

export const MOCK_PROFILES_RESPONSE: ProfilesResponse = {
  profiles: [
    {
      id: 'unique-profile-id-1',
      createdOn: '2021-02-25T16:25:55.497081',
      evaluationSubmittedOn: '2021-02-25T16:25:55.497081',
      pronoun: 'HE',
      dialect: 'en-GB',
      colourScores: {
        conscious: {
          blue: 10,
          green: 9,
          red: 8,
          yellow: 7,
        },
        lessConscious: {
          blue: 10,
          green: 9,
          red: 8,
          yellow: 7,
        },
        opposite: {
          blue: 10,
          green: 9,
          red: 8,
          yellow: 7,
        },
      },
      type: 'DISCOVERY_PERSONAL_PROFILE',
      supportedDialects: [
        'af-ZA',
        'id-ID',
        'bg-BG',
        'zh-CN',
        'zh-HK',
        'hr-HR',
        'cs-CZ',
        'da-DK',
        'nl-NL',
        'en-GB',
        'en-ZA',
        'et-EE',
        'fi-FI',
        'fr-FR',
        'fr-CA',
        'ka-GE',
        'de-DE',
        'el-GR',
        'hu-HU',
        'it-IT',
        'ja-JP',
        'ko-KR',
        'lt-LT',
        'nb-NO',
        'pl-PL',
        'pt-BR',
        'pt-PT',
        'ro-RO',
        'ru-RU',
        'sr-RS',
        'sk-SK',
        'sl-SI',
        'es-MX',
        'es-ES',
        'sv-SE',
        'tr-TR',
        'uk-UA',
        'vi-VN',
      ],
    },
    {
      id: 'unique-profile-id-2',
      createdOn: '2021-02-25T16:25:55.497081',
      evaluationSubmittedOn: '2021-02-25T16:25:55.497081',
      pronoun: 'THEY',
      dialect: 'en-ZA',
      colourScores: {
        lessConscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        conscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        opposite: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
      },
      type: 'DISCOVERY_PERSONAL_PROFILE',
      supportedDialects: [
        'af-ZA',
        'id-ID',
        'bg-BG',
        'zh-CN',
        'zh-HK',
        'hr-HR',
        'cs-CZ',
        'da-DK',
        'nl-NL',
        'en-GB',
        'en-ZA',
        'et-EE',
        'fi-FI',
        'fr-FR',
        'fr-CA',
        'ka-GE',
        'de-DE',
        'el-GR',
        'hu-HU',
        'it-IT',
        'ja-JP',
        'ko-KR',
        'lt-LT',
        'nb-NO',
        'pl-PL',
        'pt-BR',
        'pt-PT',
        'ro-RO',
        'ru-RU',
        'sr-RS',
        'sk-SK',
        'sl-SI',
        'es-MX',
        'es-ES',
        'sv-SE',
        'tr-TR',
        'uk-UA',
        'vi-VN',
      ],
    },
    {
      id: 'unique-profile-id-4',
      createdOn: '2021-02-25T16:25:55.497081',
      evaluationSubmittedOn: '2021-02-25T16:25:55.497081',
      pronoun: 'THEY',
      dialect: 'en-ZA',
      colourScores: {
        lessConscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        conscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        opposite: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
      },
      type: 'EXPLORE_PROFILE',
      supportedDialects: ['en-GB', 'da-DK', 'de-DE', 'nl-NL', 'es-ES', 'fr-FR'],
    },
  ],
};

type MockInviteeProps = {
  id?: string;
  status?: ProfileStatus;
  reusePreference?: ProfileReuseStatus;
  createdAt?: string;
};
export const MOCK_INVITEE_PROFILE = ({
  id = 'some-profile-id',
  status = 'NONE',
  reusePreference = 'NONE',
  createdAt,
}: MockInviteeProps): InviteeProfile => {
  return {
    id,
    status,
    reusePreference,
    ...(createdAt && { createdAt }),
  };
};
