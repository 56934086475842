import {
  ChapterCost,
  InviteeResponse,
  OrganisationResponse,
  PractitionerData,
  TeamResponse,
  EventResponseWithChapters,
  GetContributorsResponse,
  OrganisationSupportedRole,
} from 'api';
import { TIMEZONES, INSIGHTS_ORGANISATION_ID } from 'variables';
import { InviteesResponse } from 'api/events/eventApiTypes';
import { LearnerContributors } from 'api/organisationGroups/organisationGroupsTypes';
import { MOCK_INVITEE_PROFILE } from 'mocks/mock-data/profiles';
import {
  EventsHttpResponse,
  LearnersHttpResponse,
  OrganisationHttpResponse,
  PractitionersHttpResponse,
} from './types';

export const CHAPTER_COSTS: ChapterCost[] = [
  { name: 'FOUNDATION', cost: 100 },
  { name: 'PERSONAL_ACHIEVEMENT', cost: 50 },
  { name: 'EFFECTIVE_SELLING', cost: 50 },
  { name: 'MANAGEMENT', cost: 20 },
  { name: 'INTERVIEW', cost: 10 },
];

export const EVENT_RESPONSE_WITH_CHAPTERS: EventResponseWithChapters = {
  id: 'first-event',
  name: 'default name',
  dialect: 'en-GB',
  startsAt: '2030-09-02T08:00:00Z',
  endsAt: '2030-09-02T09:00:00Z',
  deadlineAt: '2030-09-01T22:59:59.999Z',
  eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
  productType: 'DISCOVERY_PERSONAL_PROFILE',
  eventStatus: 'ACTIVE',
  timezone: TIMEZONES[0],
  practitioners: [],
  location: {
    type: 'REMOTE',
    meetingLink: 'https://www.insights.com',
    details: 'test details',
  },
  notificationFrequency: 'WEEKLY',
  organisation: {
    id: 'insights',
    name: 'insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  chapters: [
    {
      name: 'FOUNDATION',
      cost: 100,
    },
  ],
};

export const IDTL_EVENT_RESPONSE_WITH_CHAPTERS: EventResponseWithChapters = {
  id: '9b27c610-1e2f-4e79-a211-a067946a6e2b',
  name: 'My IDTL Experience',
  dialect: 'en-GB',
  startsAt: '2030-09-02T00:00:00Z',
  endsAt: '2030-09-02T03:00:00Z',
  deadlineAt: '',
  eventType: 'INSIGHTS_SELF_AWARE_LEADER',
  eventStatus: 'ACTIVE',
  productType: 'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
  timezone: TIMEZONES[0],
  practitioners: [],
  location: {
    type: 'REMOTE',
    meetingLink: 'https://www.insights.com',
    details: 'test details',
  },
  notificationFrequency: 'WEEKLY',
  organisation: {
    id: 'insights',
    name: 'insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  chapters: [
    {
      name: 'FOUNDATION',
      cost: 100,
    },
  ],
};

export const DFC_EVENT_RESPONSE_WITH_CHAPTERS: EventResponseWithChapters = {
  id: 'some-id',
  name: 'default name',
  dialect: 'en-GB',
  startsAt: '2030-09-02T08:00:00Z',
  endsAt: '2030-09-02T09:00:00Z',
  deadlineAt: '2030-09-01T22:59:59.999Z',
  eventType: 'INSIGHTS_DISCOVERY_FULL_CIRCLE',
  eventStatus: 'ACTIVE',
  productType: 'DISCOVERY_FULL_CIRCLE_PROFILE',
  timezone: TIMEZONES[0],
  practitioners: [
    {
      fullName: 'Homer J Simpson',
      emailAddress: 'h.simpson@insights.com',
      jobTitle: 'INSIGHTS_ADMIN',
      leadingColourEnergy: 'CoolBlue',
      id: '7d197f94-4d81-412c-9d72-5f813e4020b9',
      roles: ['SUPER_ADMIN'],
      enabled: true,
      licensedProducts: ['DISCOVERY_FULL_CIRCLE_PROFILE'],
    },
  ],
  location: {
    type: 'REMOTE',
    meetingLink: 'https://www.insights.com',
    details: 'test details',
  },
  notificationFrequency: 'WEEKLY',
  organisation: {
    id: 'insights',
    name: 'insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  chapters: [
    {
      name: 'FOUNDATION',
      cost: 100,
    },
  ],
};

export const EXPLORE_EVENT_RESPONSE: EventResponseWithChapters = {
  id: 'explore-id',
  name: 'explore',
  dialect: 'en-GB',
  startsAt: '2030-09-02T08:00:00Z',
  endsAt: '2030-09-02T09:00:00Z',
  deadlineAt: '2030-09-01T22:59:59.999Z',
  eventType: 'INSIGHTS_EXPLORE',
  productType: 'DISCOVERY_EXPLORE_PROFILE',
  timezone: 'GMT',
  eventStatus: 'ACTIVE',
  location: {
    type: 'REMOTE',
    meetingLink: '',
    details: '',
  },
  notificationFrequency: 'NEVER',
  practitioners: [],
  organisation: {
    id: 'insights',
    name: 'insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  customEmailMessage: '',
  wallet: {
    availableUnits: 0,
    id: '6f83c783-165f-4b26-9084-d911fb6b012d',
  },
  chapters: [
    {
      name: 'EXPLORE',
      cost: 10,
    },
  ],
};

export const DFC_EVENT_RESPONSE_WITH_CHAPTERS_NEW: EventResponseWithChapters = {
  id: '64db8a15-8ad6-4b77-8453-28f755d4dd06',
  name: 'DFC_EVENT',
  dialect: 'en-GB',
  startsAt: '2023-07-30T00:00:00Z',
  endsAt: '2023-07-31T00:00:00Z',
  deadlineAt: '2023-07-29T23:59:59.999Z',
  eventType: 'INSIGHTS_DISCOVERY_FULL_CIRCLE',
  productType: 'DISCOVERY_FULL_CIRCLE_PROFILE',
  timezone: 'GMT',
  eventStatus: 'ACTIVE',
  location: {
    type: 'REMOTE',
    meetingLink: '',
    details: '',
  },
  notificationFrequency: 'NEVER',
  practitioners: [
    {
      fullName: 'Homer J Simpson',
      emailAddress: 'h.simpson@insights.com',
      jobTitle: 'INSIGHTS_ADMIN',
      leadingColourEnergy: 'CoolBlue',
      id: '7d197f94-4d81-412c-9d72-5f813e4020b9',
      roles: ['SUPER_ADMIN'],
      enabled: true,
      licensedProducts: ['DISCOVERY_FULL_CIRCLE_PROFILE'],
    },
  ],
  organisation: {
    name: 'Insights',
    id: 'insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  wallet: {
    availableUnits: 0,
    id: '6f83c783-165f-4b26-9084-d911fb6b012d',
  },
  chapters: [
    {
      name: 'DFC',
      cost: 170,
    },
  ],
};

export const DFC_INVITEE: InviteesResponse = {
  invitees: [
    {
      id: '1',
      email: 'a.a@mail.com',
      fullName: 'A A',
      status: 'ADDED',
      chapters: [
        {
          name: 'DFC',
          status: 'PURCHASED',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'HE',

      lastInviteAt: undefined,
      profileLastSentAt: undefined,
      leadingColourEnergy: 'CoolBlue',
      evaluation: undefined,
      scores: {
        blue: 334,
        green: 307,
        yellow: 333,
        red: 280,
        preferred: ['green', 'blue', 'yellow'],
      },
      profile: MOCK_INVITEE_PROFILE({
        status: 'AVAILABLE_FOR_REUSE',
        createdAt: '2024-02-28T08:36:48.987438Z',
      }),
    },
  ],
};

export const DFC_LEARNER_CONTRIBUTOR: LearnerContributors[] = [
  {
    learner: {
      id: '2',
      email: 'a.a@mail.com',
      fullName: 'A A',
      status: 'READY_TO_PURCHASE',
      profile: MOCK_INVITEE_PROFILE({}),
      evaluation: {
        submittedOn: '2023-08-22T07:40:08.869805Z',
      },
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
    },
    contributors: [],
  },
];

export const DFC_INVITEES_RESPONSE_NEW: InviteesResponse = {
  invitees: [
    {
      id: '1f570247-9865-4bf9-ae4d-7cba6c444d90',
      email: 'a.a@mail.com',
      fullName: 'A A',
      status: 'READY_TO_PURCHASE',
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'THEY',
      evaluation: {
        submittedOn: '2023-07-18T15:42:15.921036Z',
      },
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: '024bbfc0-04c1-4cf4-a56a-566bd8d48dd2',
      email: 'b.b@mail.com',
      fullName: 'B B',
      status: 'READY_TO_PURCHASE',
      chapters: [
        {
          name: 'DFC',
          status: 'PURCHASED',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'SHE',

      lastInviteAt: '2023-07-18T15:41:47.638403Z',
      leadingColourEnergy: 'CoolBlue',
      evaluation: {
        submittedOn: '2023-07-18T19:55:39.123151Z',
      },
      profile: MOCK_INVITEE_PROFILE({
        status: 'PURCHASED',
        createdAt: '2023-07-18T19:53:48.302668Z',
      }),
    },
    {
      id: '62f55133-2a10-4433-8b4c-b8da5d99d8d8',
      email: 'c.c@mail.com',
      fullName: 'C C',
      status: 'INVITE_SENT',
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'THEY',
      lastInviteAt: '2023-07-18T15:43:00.167563Z',
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: '7573829b-9768-4f95-84b0-b136ca6b2604',
      email: 'd.d@mail.com',
      fullName: 'D D',
      status: 'INVITE_SENT',
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'THEY',

      lastInviteAt: '2023-07-18T15:43:00.188525Z',
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: 'f45c92c0-27eb-4562-ad75-75d833b0f03d',
      email: 'b.b@mail.com',
      fullName: 'B B',
      status: 'READY_TO_PURCHASE',
      chapters: [
        {
          name: 'DFC',
          status: 'PURCHASED',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'SHE',

      leadingColourEnergy: 'CoolBlue',
      evaluation: {
        submittedOn: '2023-07-18T19:55:39.123151Z',
      },
      profile: MOCK_INVITEE_PROFILE({
        status: 'PURCHASED',
        createdAt: '2023-07-18T19:53:48.302668Z',
      }),
    },
    {
      id: '67a9aa94-6dba-400f-805f-52242ce0bdfe',
      email: 'a.a@mail.com',
      fullName: 'A A',
      status: 'INVITE_SENT',
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'THEY',

      lastInviteAt: '2023-07-18T18:29:17.018027Z',
      evaluation: {
        submittedOn: '2023-07-18T15:42:15.921036Z',
      },
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: '9ba7431d-97bc-45f4-8db8-8d6a753d781b',
      email: 'c.c@mail.com',
      fullName: 'C C',
      status: 'INVITE_SENT',
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'THEY',

      lastInviteAt: '2023-07-18T18:29:17.032933Z',
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: '2fa75af4-1579-4a7e-8c33-e2f610f6b00b',
      email: 'd.d@mail.com',
      fullName: 'D D',
      status: 'INVITE_SENT',
      profile: MOCK_INVITEE_PROFILE({}),
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
      dialect: 'en-GB',
      pronoun: 'THEY',

      lastInviteAt: '2023-07-18T19:54:16.124946Z',
    },
  ],
};

export const DFC_CONTRIBUTORS_RESPONSE_NEW: GetContributorsResponse = {
  invitees: [
    {
      id: '1f570247-9865-4bf9-ae4d-7cba6c444d90',
      readyToPurchase: true,
      contributorInvitees: [
        {
          id: '024bbfc0-04c1-4cf4-a56a-566bd8d48dd2',
          email: 'b.b@mail.com',
          fullName: 'B B',
          status: 'READY_TO_PURCHASE',
          evaluation: {
            submittedOn: '2023-07-18T15:42:15.921036Z',
          },
        },
        {
          id: '62f55133-2a10-4433-8b4c-b8da5d99d8d8',
          email: 'c.c@mail.com',
          fullName: 'C C',
          status: 'INVITE_SENT',
        },
        {
          id: '7573829b-9768-4f95-84b0-b136ca6b2604',
          email: 'd.d@mail.com',
          fullName: 'D D',
          status: 'INVITE_SENT',
        },
      ],
    },
    {
      id: 'f45c92c0-27eb-4562-ad75-75d833b0f03d',
      readyToPurchase: true,
      contributorInvitees: [
        {
          id: '67a9aa94-6dba-400f-805f-52242ce0bdfe',
          email: 'a.a@mail.com',
          fullName: 'A A',
          status: 'PROFILE_GENERATED',
          evaluation: {
            submittedOn: '2023-07-18T18:29:57.80486Z',
          },
        },
        {
          id: '9ba7431d-97bc-45f4-8db8-8d6a753d781b',
          email: 'c.c@mail.com',
          fullName: 'C C',
          status: 'PROFILE_GENERATED',
          evaluation: {
            submittedOn: '2023-07-18T18:30:51.714928Z',
          },
        },
        {
          id: '2fa75af4-1579-4a7e-8c33-e2f610f6b00b',
          email: 'd.d@mail.com',
          fullName: 'D D',
          status: 'READY_TO_PURCHASE',
          evaluation: {
            submittedOn: '2023-07-18T19:55:39.123151Z',
          },
        },
      ],
    },
  ],
};

export const DFC_LEARNER_CONTRIBUTORS_RESPONSE: LearnerContributors[] = [
  {
    learner: {
      id: 'ed7d4521-2df6-4cdc-beee-e027f5c1b3cd',
      email: 'a.a@mail.com',
      fullName: 'A A',
      status: 'READY_TO_PURCHASE',

      profile: MOCK_INVITEE_PROFILE({}),
      evaluation: {
        submittedOn: '2023-08-22T07:40:08.869805Z',
      },
      chapters: [
        {
          name: 'DFC',
          status: 'PENDING',
        },
      ],
    },
    contributors: [
      {
        id: 'e4cecb9b-ba7e-4109-b1f8-d06a241ed25e',
        email: 'b.b@mail.com',
        fullName: 'B B',
        status: 'READY_TO_PURCHASE',
        discoveryProfile: null,
        evaluation: {
          submittedOn: '2023-08-22T07:40:08.869805Z',
        },
      },
      {
        id: '838d7390-b369-4931-967e-5f1e465a116c',
        email: 'c.c@mail.com',
        fullName: 'C C',
        status: 'INVITE_SENT',
      },
    ],
  },
];

export const EVENTS_RESPONSE: EventsHttpResponse = {
  events: [
    {
      id: 'first-event',
      name: 'default name',
      dialect: 'en-GB',
      startsAt: '1970-01-08T00:00:00Z',
      endsAt: '1970-01-08T01:00:00Z',
      deadlineAt: '1970-01-06T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
      productType: 'DISCOVERY_PERSONAL_PROFILE',
      eventStatus: 'ACTIVE',
      timezone: TIMEZONES[0],
      practitioners: [],
      location: {
        type: 'REMOTE',
        meetingLink: 'https://www.insights.com',
        details: 'test details',
      },
      notificationFrequency: 'WEEKLY',
      organisation: {
        id: 'insights',
        name: 'Insights',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
    },
    {
      id: 'second-event',
      name: 'default name',
      dialect: 'en-GB',
      startsAt: '1970-01-08T00:00:00Z',
      endsAt: '1970-01-08T01:00:00Z',
      deadlineAt: '1970-01-06T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
      productType: 'DISCOVERY_PERSONAL_PROFILE',
      eventStatus: 'ACTIVE',
      timezone: TIMEZONES[0],
      practitioners: [],
      location: {
        type: 'REMOTE',
        meetingLink: 'https://www.insights.com',
        details: 'test details',
      },
      notificationFrequency: 'WEEKLY',
      organisation: {
        id: 'insights',
        name: 'Insights',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
    },
  ],
};

export const MY_ORG_RESPONSE: OrganisationHttpResponse = {
  name: 'My organisation',
  id: 'insights',
  dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
};

export const PRACTITIONER_RESPONSE: PractitionerData = {
  fullName: 'John Doe',
  emailAddress: 'jdoe@example.com',
  id: 'an-id',
  roles: ['STANDARD'],
  leadingColourEnergy: 'CoolBlue',
  licensedProducts: ['DISCOVERY_PERSONAL_PROFILE'],
  enabled: true,
  jobTitle: 'CLIENT_PRACTITIONER',
};

export const PRACTITIONERS_RESPONSE: PractitionersHttpResponse = {
  practitioners: [
    {
      fullName: 'Insights One',
      emailAddress: 'email1@insights.com',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      id: '93a14506-1593-4c3f-9435-5e5f9d72306a',
      roles: ['SUPER_ADMIN', 'STANDARD'],
      licensedProducts: [
        'DISCOVERY_SELF_AWARE_PROFILE',
        'DISCOVERY_PERSONAL_PROFILE',
      ],
      enabled: true,
    },
    {
      fullName: 'Insights Two',
      emailAddress: 'email2@insights.com',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      id: 'aa1fe8d6-04e6-4560-b817-58665e1a4944',
      roles: ['SUPER_ADMIN', 'STANDARD'],
      licensedProducts: [
        'DISCOVERY_SELF_AWARE_PROFILE',
        'DISCOVERY_PERSONAL_PROFILE',
      ],
      enabled: true,
    },
  ],
};

export const LEARNERS_RESPONSE: LearnersHttpResponse = {
  learners: [
    {
      id: 'learner-1-id',
      fullName: 'Homer Simpson',
      knownAs: 'Homer',
      primaryEmail: 'homer.simpson@insights.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
      leadingColourEnergy: 'SunshineYellow',
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
    {
      id: 'learner-2-id',
      fullName: 'Seymour Skinner',
      knownAs: 'Seymour',
      primaryEmail: 'seymour.skinner@insights.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
    {
      id: 'learner-3-id',
      fullName: 'Apu Nahasapeemapetilon',
      knownAs: 'Apu',
      primaryEmail: 'apu.nahasapeemapetilon@insights.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
    {
      id: 'learner-4-id',
      fullName: 'Clancy Wiggum',
      knownAs: 'Clancy',
      primaryEmail: 'clancy.wiggum@insights.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
  ],
};

export const TEAM_RESPONSE: Required<TeamResponse> = {
  id: 'team-id',
  name: 'The Be Sharps',
  visibility: 'PRIVATE',
  organisationContext: {
    distinctLearnerCount: 2,
    type: 'SINGLE',
    organisations: [
      {
        id: 'org-1-id',
        name: 'Org 1',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
    ],
  },
  migrated: false,
  location: null,
  learnerCount: 2,
};

export const MULTI_ORG_TEAM_RESPONSE: Required<TeamResponse> = {
  id: 'team-id',
  name: 'The Be Sharps',
  visibility: 'PRIVATE',
  organisationContext: {
    distinctLearnerCount: 2,
    type: 'MULTIPLE',
    organisations: [
      {
        id: 'org-1-id',
        name: 'Org 1',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
      {
        id: 'org-2-id',
        name: 'Org 2',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
      {
        id: 'org-3-id',
        name: 'Org 3',
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
    ],
  },
  migrated: false,
  location: null,
  learnerCount: 2,
};

export const TEAMS_RESPONSE: Required<TeamResponse>[] = [
  {
    id: 'team-id',
    name: 'The Be Sharps',
    visibility: 'PRIVATE',
    learnerCount: 3,
    organisationContext: {
      type: 'MULTIPLE',
      distinctLearnerCount: 10,
      organisations: [
        {
          id: 'org-1',
          name: 'org-1-name',
          learnerCount: 5,
          dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
        },
        {
          id: 'org-2',
          name: 'org-2-name',
          learnerCount: 5,
          dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
        },
      ],
    },
    migrated: false,
    location: null,
  },
];

export const INVITEE_RESPONSE: InviteeResponse = {
  id: 'invitee-0',
  email: 'michelle@steps.com',
  fullName: 'Michelle Visage',
  status: 'ADDED',
  chapters: [],
  dialect: 'en-GB',
  pronoun: 'SHE',

  profile: MOCK_INVITEE_PROFILE({}),
};

export const IDTL_INVITEE_RESPONSE: InviteeResponse = {
  id: 'idtl-invitee-0',
  email: 'jay@idtl.com',
  fullName: 'Jay Lok',
  status: 'READY_TO_PURCHASE',
  chapters: [{ name: 'IDTL', status: 'PENDING' }],
  dialect: 'en-GB',
  pronoun: 'HE',

  profileLastSentAt: '2022-01-01T00:00:00.000000Z',
  profile: MOCK_INVITEE_PROFILE({ createdAt: '2030-02-03T00:00:00.000000Z' }),
};
export const EXPLORE_INVITEES_RESPONSE: { invitees: InviteeResponse[] } = {
  invitees: [
    {
      id: 'invitee-1',
      email: 'lee@steps.com',
      fullName: 'Lee Latchford-Evans',
      status: 'READY_TO_PURCHASE',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'HE',

      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: 'invitee-2',
      email: 'claire@steps.com',
      fullName: 'Claire Richards',
      status: 'READY_TO_PURCHASE',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'SHE',

      lastInviteAt: '2030-01-01T00:00:00.000000Z',
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: 'invitee-3',
      email: 'faye@steps.com',
      fullName: 'Faye Tozer',
      status: 'READY_TO_PURCHASE',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'HE',

      profile: MOCK_INVITEE_PROFILE({ status: 'PURCHASED' }),
      scores: {
        blue: 200,
        green: 100,
        yellow: 50,
        red: 10,
        preferred: ['blue', 'green'],
      },
    },
  ],
};

export const INVITEES_RESPONSE: { invitees: InviteeResponse[] } = {
  invitees: [
    {
      id: 'invitee-1',
      email: 'lee@steps.com',
      fullName: 'Lee Latchford-Evans',
      status: 'ADDED',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'HE',
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: 'invitee-2',
      email: 'claire@steps.com',
      fullName: 'Claire Richards',
      status: 'INVITE_SENT',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'SHE',

      lastInviteAt: '2030-01-01T00:00:00.000000Z',
      profile: MOCK_INVITEE_PROFILE({ status: 'PURCHASED' }),
    },
    {
      id: 'invitee-3',
      email: 'lisa@steps.com',
      fullName: 'Lisa Scott-Lee',
      status: 'INVITE_SENT',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'SHE',

      lastInviteAt: '2020-01-01T00:00:00.000000Z',
      profileLastSentAt: '2022-01-01T00:00:00.000000Z',
      profile: MOCK_INVITEE_PROFILE({ status: 'PURCHASED' }),
    },
    {
      id: 'invitee-4',
      email: 'faye@steps.com',
      fullName: 'Ready to purchase - Faye Tozer',
      status: 'READY_TO_PURCHASE',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'THEY',

      leadingColourEnergy: 'SunshineYellow',
      evaluation: {
        submittedOn: '2022-06-01T00:00:00.000000Z',
      },
      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: 'invitee-5',
      email: 'ian@steps.com',
      fullName: 'Ian Watkins',
      status: 'READY_TO_PURCHASE',
      chapters: [{ name: 'FOUNDATION', status: 'PURCHASED' }],
      dialect: 'en-GB',
      pronoun: 'HE',

      leadingColourEnergy: 'SunshineYellow',
      profileLastSentAt: '2022-01-01T00:00:00.000000Z',
      profile: MOCK_INVITEE_PROFILE({
        status: 'AVAILABLE_FOR_REUSE',
        createdAt: '2030-01-01T00:00:00.000000Z',
      }),
    },
    {
      id: 'invitee-6',
      email: 'bob@fish.com',
      fullName: 'Bob Fish',
      status: 'READY_TO_PURCHASE',
      chapters: [{ name: 'FOUNDATION', status: 'PURCHASED' }],
      dialect: 'en-GB',
      pronoun: 'HE',

      leadingColourEnergy: 'SunshineYellow',
      profileLastSentAt: '2022-01-01T00:00:00.000000Z',
      profile: MOCK_INVITEE_PROFILE({
        status: 'AVAILABLE_FOR_REUSE',
        createdAt: '2030-02-03T00:00:00.000000Z',
      }),
    },
    {
      id: 'invitee-7',
      email: 'brandon@themummy.com',
      fullName: 'Ready to purchase - Brandon Fraser',
      status: 'READY_TO_PURCHASE',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'THEY',

      leadingColourEnergy: 'SunshineYellow',
      evaluation: {
        submittedOn: '2022-01-01T00:00:00.000000Z',
      },
      profileLastSentAt: '2022-01-01T00:00:00.000000Z',
      profile: MOCK_INVITEE_PROFILE({ status: 'NONE' }),
    },
  ],
};

export const DFC_INVITEES_RESPONSE: InviteesResponse = {
  invitees: [
    {
      id: 'dfc-learner-id',
      email: 'lee@steps.com',
      fullName: 'Lee Latchford-Evans',
      status: 'ADDED',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'HE',

      profile: MOCK_INVITEE_PROFILE({}),
    },
    {
      id: 'dfc-learner-id-2',
      email: 'foo@example.com',
      fullName: 'Foo Bar',
      status: 'ADDED',
      chapters: [],
      dialect: 'en-GB',
      pronoun: 'SHE',

      profile: MOCK_INVITEE_PROFILE({
        status: 'AVAILABLE_FOR_REUSE',
        createdAt: '2030-02-03T00:00:00.000000Z',
      }),
    },
  ],
};

export const PRACTITIONER_ORGANISATIONS: {
  organisations: OrganisationResponse[];
} = {
  organisations: [
    {
      name: 'Insights Org',
      id: INSIGHTS_ORGANISATION_ID,
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
    {
      name: 'LMS Org',
      id: '7b27c611-1e2f-4e79-a211-a067946a6e2b',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
  ],
};

export const PRACTITIONER_ORGANISATIONS_SUPPORTED_ROLES: {
  organisationSupportedRoles: OrganisationSupportedRole[];
} = {
  organisationSupportedRoles: [
    {
      organisationId: INSIGHTS_ORGANISATION_ID,
      supportedRoles: ['STANDARD', 'SUPER_ADMIN'],
    },
    {
      organisationId: '7b27c611-1e2f-4e79-a211-a067946a6e2b',
      supportedRoles: ['STANDARD'],
    },
  ],
};
