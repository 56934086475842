/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import {
  ButtonV2,
  Dialog,
  Span,
  SvgAddRegular,
} from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useAuth } from 'contexts/AuthContext';
import {
  EventBudget,
  useGetPractitionerWallet,
  useTransferUnitsBetweenWallets,
} from 'api';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_ROOTS } from 'variables';
import TransferUnitsDialog from './TransferUnitsDialog';
import Section from './Section';

export type WalletProps = {
  eventBalance: number;
  profileCost: number;
  eventWalletId: string;
  organisationId: string;
  eventStatus: 'ACTIVE' | 'CANCELLED';
  pastEndDate?: boolean;
  budget: EventBudget;
};

const Wallet = ({
  eventBalance,
  profileCost,
  eventWalletId,
  organisationId,
  eventStatus,
  pastEndDate = false,
  budget,
}: WalletProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [openTransferUnits, setOpenTransferUnits] = useState(false);
  const [openTransferFailed, setOpenTransferFailed] = useState(false);
  const { status, data: wallet, refetch } = useGetPractitionerWallet(user!.id);
  const availableUnits = wallet ? wallet.availableUnits : 0;
  const { mutate } = useTransferUnitsBetweenWallets();
  const recoverableUnits = eventBalance;

  const useTransferUnits = (): ((
    amount: number,
    isAdding: boolean,
  ) => void) => {
    const queryClient = useQueryClient();
    const emailStrategy = 'SUPPRESS';

    return (amount: number, isAdding: boolean) => {
      const fromWalletId = isAdding ? wallet!.id : eventWalletId;
      const toWalletId = isAdding ? eventWalletId : wallet!.id;
      const params = {
        fromWalletId,
        toWalletId,
        amount,
        organisationId,
        emailStrategy,
      };
      return mutate(params, {
        onSuccess: () => {
          setOpenTransferUnits(false);
          queryClient
            .invalidateQueries({
              queryKey: [QUERY_KEY_ROOTS.EVENT_DATA],
            })
            .then();
          queryClient
            .invalidateQueries({
              queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
            })
            .then();

          refetch().then();
        },
        onError: () => {
          setOpenTransferFailed(true);
        },
      });
    };
  };

  const closeErrorDialog = () => {
    setOpenTransferFailed(false);
    window.location.reload();
  };

  const transferUnits = useTransferUnits();

  return (
    <>
      <Box marginBottom={4}>
        <Span>{t('ui.event-management.events.unit-management.info')}</Span>
        <Card
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            border: 'none',
            boxShadow: 'none',
          })}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              sx={(theme) => ({
                borderRight: `1px solid ${theme.palette.divider}`,
              })}
            >
              <Section
                testId="wallet-budget"
                title="ui.event-management.events.unit-management.subtitle.budget"
                value={budget.total}
              />
              <Box
                sx={(theme) => ({
                  padding: theme.spacing(2),
                  alignItems: 'left',
                })}
              >
                <ButtonV2
                  sx={(theme) => ({
                    width: '100%',
                    [theme.breakpoints.up(1024)]: {
                      paddingLeft: 'initial',
                    },
                  })}
                  buttonVariant="outlined"
                  buttonColour="primary"
                  buttonText={t(
                    'ui.event-management.events.unit-management.add-modify-my-units',
                  )}
                  buttonIcon={<SvgAddRegular />}
                  onClick={() => setOpenTransferUnits(true)}
                  disabled={
                    status !== 'success' ||
                    eventStatus === 'CANCELLED' ||
                    pastEndDate
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.palette.divider}`,
                })}
              >
                <Section
                  testId="wallet-potential"
                  title="ui.event-management.events.unit-management.subtitle.potential"
                  value={budget.potentialSpend}
                />
              </Box>
              <Section
                testId="wallet-spend"
                title="ui.event-management.events.unit-management.subtitle.spend"
                value={budget.spent}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
      <TransferUnitsDialog
        open={openTransferUnits}
        profileCost={profileCost}
        availableUnits={availableUnits}
        recoverableUnits={recoverableUnits}
        onClose={() => setOpenTransferUnits(false)}
        doTransferUnits={transferUnits}
      />
      <Dialog
        open={Boolean(openTransferFailed)}
        title={t(
          'ui.event-management.wallet.recover-units-dialog.error.heading',
        )}
        content={t(
          'ui.event-management.events.transfer-units-dialog.recover-validation.max',
        )}
        secondaryButtonText={t(
          'ui.event-management.events.add-learner.idtl-error-dialog.okay',
        )}
        onClose={closeErrorDialog}
      />
    </>
  );
};

export default Wallet;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
