/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Canvg } from 'canvg';
import { isChrome } from '@insights-ltd/design-library/utils';
import {
  getSvgElementById,
  openNewWindow,
  renderCanvasToFile,
  saveCanvasToFile,
  svgDataCanvasAndContextFromSvgElement,
} from 'pages/DownloadTeamWheel/globalMethods';

const downloadSVG = (
  fileName: string,
  svgId: string,
  forceNativeInChrome = false,
  setWidth?: number,
  setHeight?: number,
): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    const $svg = getSvgElementById(svgId);
    if (!$svg) reject(new Error('Invalid svgId!'));
    const width = setWidth || $svg.width.baseVal.value * 2;
    const height = setHeight || $svg.height.baseVal.value * 2;
    const { canvas, ctx, svgData } = svgDataCanvasAndContextFromSvgElement(
      $svg,
      width,
      height,
    );
    if (isChrome() && !forceNativeInChrome) {
      const v = Canvg.fromString(ctx!, svgData);
      v.render()
        .then(() => {
          if (isChrome()) {
            saveCanvasToFile(canvas, fileName)
              .catch(() => reject(new Error('Save to file failed!')))
              .then(() => resolve(true));
          }
        })
        .catch(() => {
          reject(new Error('canvg render failed!'));
        });
    } else {
      if (!isChrome()) openNewWindow();
      renderCanvasToFile(svgData, ctx!, canvas, width, height, fileName)
        .catch(() => reject(new Error('Native render failed!')))
        .then(() => resolve(true));
    }
  });

export default downloadSVG;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
