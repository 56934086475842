import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useIsPermitted from 'components/hooks/useIsPermitted';
import { queryStatus } from 'utils/queryStatus';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_ROOTS } from 'variables';
import {
  PractitionerRequest,
  RequestError,
  useGetPractitioner,
  useUpdatePractitioner,
} from 'api';
import { EditAccountForm } from './EditAccountForm';

const isGeneralError = (isError: boolean, error: any): boolean =>
  isError && !(error instanceof RequestError);

const EditAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { status: getPractitionerStatus, data } = useGetPractitioner();
  const {
    mutate,
    isPending,
    isError,
    reset: resetMutation,
    error,
  } = useUpdatePractitioner();

  const { status: roleChangeIsPermittedStatus } = useIsPermitted([
    { action: 'Practitioner_UpdateRoles', scope: 'Self' },
  ]);

  const status = queryStatus(
    getPractitionerStatus,
    roleChangeIsPermittedStatus,
  );

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.my-account.edit.error.load')}
      />
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { id, enabled, leadingColourEnergy, ...formData } = data!;

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.edit-account"
      activeTextKey="ui.event-management.my-account.edit.title"
      crumbs={{
        '/': t('ui.event-management.dashboard.home.title'),
        '/account': t('ui.event-management.my-account.title'),
      }}
      headingKey="ui.event-management.my-account.edit.title"
    >
      <EditAccountForm
        formData={{
          ...formData,
          leadingColourEnergy:
            leadingColourEnergy === null ? undefined : leadingColourEnergy,
        }}
        formHeaderTitle={t('ui.event-management.my-account.edit.details')}
        showProducts={false}
        isSuperAdmin={false}
        showPreferredDialect
        submitHandler={(accountData: PractitionerRequest, onError: any) => {
          if (isPending) return;
          mutate(
            {
              formData: accountData,
            },
            {
              onSuccess: async () => {
                await queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY_ROOTS.PRACTITIONER_DATA],
                });
                navigate('/account');
              },
              onError,
            },
          );
        }}
      />
      <Snackbar
        open={isGeneralError(isError, error)}
        autoHideDuration={6000}
        onClose={resetMutation}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={resetMutation}
        >
          <AlertTitle>
            {t('ui.event-management.my-account.edit.error.title')}
          </AlertTitle>
          {t('ui.event-management.my-account.edit.error.details')}
        </Alert>
      </Snackbar>
    </BreadcrumbLayout>
  );
};

export default EditAccount;
