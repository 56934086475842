import { unauthenticatedFetch } from 'api/fetch';

type SsoRedirectParams = {
  code: string;
  state: string;
};

export const ssoRedirect = async (
  params: SsoRedirectParams,
): Promise<Response> => {
  const queryParams = new URLSearchParams(params).toString();
  const response = await unauthenticatedFetch(
    `/api/v1/sso/redirect?${queryParams}`,
    { method: 'POST' },
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};
