/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAuth } from 'contexts/AuthContext';
import isSufficientScope from 'utils/isSufficientScope';
import { PermissionRequirement } from 'permissions';
import { QueryStatus } from '@tanstack/react-query';
import useGetRequiredScopes from './useGetRequiredScopes';

export type UseIsPermittedConfig = {
  enabled?: boolean;
};

type ComparisonType = 'OR' | 'AND';

type UseIsPermittedReturnValue = {
  status: QueryStatus;
  isPermitted: boolean;
};

const useIsPermitted = (
  requiredPermissions: PermissionRequirement[],
  config: UseIsPermittedConfig = {},
  type: ComparisonType = 'AND',
): UseIsPermittedReturnValue => {
  const { user } = useAuth();
  const { status, scopedRequirements } = useGetRequiredScopes(
    requiredPermissions,
    config,
  );

  if (config?.enabled === false) {
    return {
      isPermitted: false,
      status: 'success',
    };
  }

  if (status === 'pending') {
    return {
      isPermitted: false,
      status: 'pending',
    };
  }
  if (status === 'error') {
    return { isPermitted: false, status: 'error' };
  }

  const getisPermitted = (
    checkScope: (req: PermissionRequirement) => boolean,
  ) => {
    if (type === 'OR') return scopedRequirements!.some(checkScope);
    return scopedRequirements!.every(checkScope);
  };

  const checkScope = (requirement: PermissionRequirement) => {
    return isSufficientScope(
      user!.permissions[requirement.action],
      requirement.scope!,
    );
  };

  return {
    status: 'success',
    isPermitted: getisPermitted(checkScope),
  };
};

export default useIsPermitted;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
