/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import { useParams } from 'react-router-dom';
import { queryStatus } from 'utils/queryStatus';
import { User } from 'types/types';
import { useGetEvaluatorLink, useGetGroupByOrganisation } from 'api';
import { useAuth } from 'contexts/AuthContext';
import AddToTeamForm from './AddToTeamForm';

type Props = {
  learners?: User[];
};

const AddToTeam = ({ learners = [] }: Props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const canViewGroups =
    user?.permissions.Organisation_Group_ReadAll &&
    user?.permissions.Organisation_Group_ReadAll === 'Global'; // Revert to !== None once NP-6773 is complete
  const { evaluatorLinkId } = useParams<{ evaluatorLinkId: string }>();
  const { data: evaluatorLink, status: evaluatorLinkStatus } =
    useGetEvaluatorLink(evaluatorLinkId!);
  const { data: groupData, status: groupStatus } = useGetGroupByOrganisation(
    evaluatorLink! && evaluatorLink?.organisation?.id,
    {
      enabled: canViewGroups,
    },
  );
  const status = queryStatus(
    evaluatorLinkStatus,
    canViewGroups ? groupStatus : 'success',
  );
  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error' || !evaluatorLink) {
    return (
      <FullScreenError message={t('ui.event-management.teams.error-loading')} />
    );
  }
  const parentLink = `/evaluator-links/${evaluatorLinkId}`;

  const evaluationLinkCrumbs: Record<string, string> = {
    '/': t('ui.event-management.events.nav.home'),
    '/evaluator-links': t('ui.event-management.evaluator-links.title'),
    [parentLink]: evaluatorLink.name,
  };

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.evaluator-links.teams.add-to-team"
      activeTextKey="ui.event-management.evaluator-links.teams.add-to-team"
      crumbs={evaluationLinkCrumbs}
      headingKey="ui.event-management.evaluator-links.teams.add-to-team"
    >
      <AddToTeamForm
        learners={learners}
        group={groupData!}
        organisation={evaluatorLink.organisation!}
      />
    </BreadcrumbLayout>
  );
};
export default AddToTeam;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
